import React, { useState, useEffect } from "react";
import "./PaymentSuccess.scss";
import moment from "moment";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import { getDateRange } from "../../helpers/activitiesHelper";
import ActivitiesSuccessSkeleton from "../../common/ActivitiesSuccessSkeleton/ActivitiesSuccessSkeleton";
import ActivitiesTicketSuccess from "../../components/TicketSuccessPages/ActivitiesTicketSuccess/ActivitiesTicketSuccess";
import Busticketsuccess from "../../components/TicketSuccessPages/Busticketsuccess/Busticketsuccess";
import BuildPackageTicketSuccess from "../../components/TicketSuccessPages/BuildPackageTicketSuccess/BuildPackageTicketSuccess";
import Flightticketsuccess from "../../components/TicketSuccessPages/Flightticketsuccess/Flightticketsuccess";
import SuccessSkeleton from "../../common/SuccessSkeleton/SuccessSkeleton";
import HotelTicketSuccess from "../../components/TicketSuccessPages/HotelTicketSuccess/HotelTicketSuccess";
import { useAuthContext } from "../../common/providers/AuthProvider"

const PaymentSucces = () => {
  const [type, setType] = useState("");
  const { user } = useAuthContext();

  const [buildTicketDetails, setbuildTicketDetails] = useState({
    bookingRefNo: "",
    message: "",
    hotelsPackageDetails: [],
    activitiesPackageDetails: [],
    tourDetails: [],
    request: {},
    loading: true,
  });

  const [activitiesTicketDetails, setActivitiesTicketDetails] = useState({
    message: "",
    TourDetails: [],
    Passengers: {},
    loading: true,
  });

  const [flightTicketsDetails, setFlightTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    BookingRefNo: null,
    flightDetails: [],
    ticketAllData: {},
    loading: true,
  });

  const [hotelTicketsDetails, setHotelTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });
  const [carTicketDetails, setCarTicketDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });
  const [busTicketsDetails, setBusTicketsDetails] = useState({
    twoWay: false,
    refNo1: null,
    refNo2: null,
    loading: true,
  });

  const params = queryString.parse(document.location.search);

  const airBook = (value) => {
    ApiClient.post("flights/airBook/" + value)
      .then((response) => {
        if (response.statusCode === 200) {
          setFlightTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            BookingRefNo: response.data.BookingRefNo,
            flightDetails: response.data.flightDetails,
            ticketAllData: response.charges.ticketAllData,
            loading: false,
          });
          // return;
        } else if (response.statusCode == 400 && response.data.length > 0) {
          setFlightTicketsDetails({
            errorMessage: response.data[0].errorDetail,
            otherMessage: "Booking Failed",
            BookingRefNo: null,
            flightDetails: [],
            ticketAllData: {},
            loading: false,
          });
        } else {
          setFlightTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            BookingRefNo: null,
            flightDetails: [],
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((error) => {
        setFlightTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
        });
      });
  };

  const busBookApi = async (preBookingRefId, ticketNo) => {
    setBusTicketsDetails((prev) => ({
      ...prev,
      loading: true,
    }));
    await ApiClient.get("buses/bookTicket?refNo=" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (ticketNo == 2) {
            setBusTicketsDetails((prev) => ({
              ...prev,

              refNo2: resp.data.referenceNo,
            }));
          } else {
            setBusTicketsDetails((prev) => ({
              ...prev,

              refNo1: resp.data.referenceNo,
            }));
          }
        }
        setBusTicketsDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const busBooking = async (ref) => {
    setType("BUS");
    let busResObj = JSON.parse(localStorage.getItem("twoWayBusData"));
    let ticketNo = 1;

    if (busResObj) {
      if (busResObj.length > 1) {
        setBusTicketsDetails({
          twoWay: true,
          refNo1: null,
          refNo2: null,
          loading: true,
        });
        await busBookApi(busResObj[0].BookingReferenceNo, ticketNo);
        ticketNo = 2;
      }
    }

    await busBookApi(ref, ticketNo);
  };

  const ActivitiesBookingApi = (ref) => {
    setType("ACTIVITIES");
    ApiClient.get("activities/tours/booking/" + ref)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200 && resp?.data) {
          setActivitiesTicketDetails({
            ...resp.data,
            message: "SUCCESS",
            loading: false,
          });
          removeActivity(user?.UserID);
        } else {
          setActivitiesTicketDetails({
            ...activitiesTicketDetails,
            message: "FAILED",
            loading: false,
          });
        }
        localStorage.clear();
      })
      .catch((err) => {
        setActivitiesTicketDetails({
          ...activitiesTicketDetails,
          message: "FAILED",
          loading: false,
        });
        return err;
      });
  };

  const packageBookingApi = (ref) => {
    ApiClient.get("buildPackage/packageBook/" + ref)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          let data = {
            ...resp.data,
            tourDetails: [],
          };

          if (Object.keys(resp.data?.request).length > 0) {
            const req = resp.data?.request;

            const tourAvlDate = getDateRange(
              req?.checkInDate,
              req?.checkOutDate
            );

            if (resp.data.activitiesPackageDetails.length > 0) {
              resp.data?.activitiesPackageDetails?.map((tour) => {
                tour?.optionData?.map((optionData) => {
                  let i = tourAvlDate.indexOf(
                    moment(optionData.tourDate).format("DD-MM-YYYY")
                  );

                  if (
                    data.tourDetails.hasOwnProperty(i) &&
                    data.tourDetails[i]?.length > 0
                  ) {
                    data.tourDetails[i].push(optionData);
                  } else {
                    data.tourDetails[i] = [optionData];
                  }
                });
              });
            }
          }

          setbuildTicketDetails({
            ...data,
            message: "SUCCESS",
            loading: false,
          });
        } else {
          setbuildTicketDetails({
            bookingRefNo: "",
            hotelsPackageDetails: [],
            activitiesPackageDetails: [],
            message: "FAILED",
            loading: false,
          });
        }
      })
      .catch((err) => {
        setbuildTicketDetails({
          bookingRefNo: "",
          hotelsPackageDetails: [],
          activitiesPackageDetails: [],
          message: "FAILED",
          loading: false,
        });
        return err;
      });
  };

  const hotelBookApi = (preBookingRefId) => {
    setHotelTicketsDetails({
      errorMessage: "",
      otherMessage: "",
      ticketAllData: {},
      loading: true,
    });

    ApiClient.post("hotels-v2/hotelBook/" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            ticketAllData: resp.data,
            loading: false,
          });
        } else if (resp.status == 400 && resp.data.length > 0) {
          setHotelTicketsDetails({
            errorMessage: resp.data[0].errorDetail,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        } else {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((err) => {
        setHotelTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          ticketAllData: {},
          loading: false,
        });
      });
  };

  const removeActivity = (userId) => {
    ApiClient.delete(`activities/removeActivity/${userId}`).then((res) => {
      if(res.status == 2000){
        sessionStorage.clear();
        localStorage.clear();
      }
    }).catch((e) => {
      console.log(e.message);
    })
  }

  useEffect(() => {
    if (params.ref && params.ref.indexOf("SYT-F") != -1) {
      setType("FLIGHT");
      airBook(params.ref);
    } else if (params.ref && params.ref.indexOf("SYT-H") != -1) {
      setType("HOTEL");
      hotelBookApi(params.ref);
    } else if (params.ref && params.ref.indexOf("User") != -1) {
      // UserWallet(params.ref);
    } else if (params.ref && params.ref.indexOf("SYT-BP") != -1) {
      setType("BUILDPACKAGE");
      packageBookingApi(params.ref);
    } else if (params.ref && params.ref.indexOf("SYT-B") != -1) {
      setType("BUS");
      busBooking(params.ref);
    } else if (params.ref && params.ref.indexOf("SYT-A") != -1) {
      ActivitiesBookingApi(params.ref);
    }
  }, []);

  return (
    <div className="success_area">
      {type === "FLIGHT" ? (
        flightTicketsDetails.loading ? (
          <SuccessSkeleton title="Flight" />
        ) : (
          <Flightticketsuccess flightTicketsDetails={flightTicketsDetails} />
        )
      ) : null}
      {type === "BUS" ? (
        busTicketsDetails.loading ? (
          <SuccessSkeleton title="Bus" />
        ) : (
          <Busticketsuccess busTicketsDetails={busTicketsDetails} />
        )
      ) : null}
      {type === "ACTIVITIES" ? (
        activitiesTicketDetails.loading ? (
          <ActivitiesSuccessSkeleton title="Activity" />
        ) : (
          <ActivitiesTicketSuccess
            activitiesTicketDetails={activitiesTicketDetails}
          />
        )
      ) : null}

      {type === "BUILDPACKAGE" ? (
        buildTicketDetails.loading ? (
          <ActivitiesSuccessSkeleton title="Build Package" />
        ) : (
          <BuildPackageTicketSuccess buildTicketDetails={buildTicketDetails} />
        )
      ) : null}

      {type === "HOTEL" ? (
        hotelTicketsDetails.loading ? (
          <SuccessSkeleton title="Hotel" />
        ) : (
          <HotelTicketSuccess hotelTicketsDetails={hotelTicketsDetails} />
        )
      ) : null}
    </div>
  );
};
export default PaymentSucces;
