import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  TimePicker,
  Popover,
} from "antd";
import moment from "moment";

import APIClient from "../../helpers/ApiClient";

import search from "../assets/vector-icons/search.png";

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  IdcardOutlined,
  PlusOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const timeFormat = "HH:mm";

const Activitytimeslot = () => {
  const [isEditMode, setIsEditMode] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [activitiesList, setActivitiesList] = useState([]);
  const [tourId, setTourId] = useState(null);
  const [dynamicPrice, setDynamicPrice] = useState(0);

  const [activitiesOptionList, setActivitiesOptionList] = useState([]);
  const [activitiesTimeSlotList, setActivitiesTimeSlotList] = useState([]);
  const [loding, setLoding] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [IsTransfer, setIsTransfer] = useState(0);
  const [ticketNumber, setTicketNumber] = useState(0);

  const [form] = Form.useForm();

  const reset = () => {
    setIsTransfer(0);
    setTicketNumber(0);
    form.resetFields();
  };

  const changeStatus = (info, status) => {
    delete info.SNo;
    delete info.timeSlotId;
    let obj = {
      ...info,
      status: status,
    };
    // updateActivitiesTimeSlot(obj);
    updateTimeSlotByTourOptionId(obj)
  };

  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const columnActivitiesTimeSlotList = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "Tour Name",
      dataIndex: "tourName",
      sorter: (a, b) => a.tourName.localeCompare(b.tourName),
    },
    {
      title: "Option Name",
      dataIndex: "optionName",
      sorter: (a, b) => a.optionName.localeCompare(b.optionName),
    },

    {
      title: "Available Date",
      dataIndex: "availableDate",
      sorter: (a, b) => a.availableDate.localeCompare(b.availableDate),
    },

    {
      title: "Opening Hours",
      dataIndex: "timeSlot",
      sorter: (a, b) => a.timeSlot.localeCompare(b.timeSlot),
    },

    {
      title: "Ticket Count",
      dataIndex: "ticketCount",
      sorter: (a, b) => a.ticketCount.localeCompare(b.ticketCount),
    },

    {
      title: "Status",

      key: "category",

      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  /** Search ****************** */
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = activitiesTimeSlotList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setActivitiesTimeSlotList(searchResults);
    } else {
      getActivitiesTimeSlot();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setLoding(false);
    getActivitiesTimeSlot();
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const updateActivitiesFormData = (currentFormData) => {
    //setIsEditMode(false);

    // setId(currentFormData.ID);

    form.setFieldsValue({
      ...currentFormData,
      startTime: moment(currentFormData.endTime, timeFormat),
      endTime: moment(currentFormData.endTime, timeFormat),
    });
    setModalVisible(true);
    setIsEditMode(true);
  };

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}

        // onClick={() => updateFlightComm(currentFlightComm)}
        >
          <EditOutlined
            onClick={() => updateActivitiesFormData(CurrentItem)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
          // onConfirm={() => deleteFlightComm(CurrentItem.PaymentTypeID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const selectTours = (e) => {
    setTourId(e);
  };

  const add = () => {
    if (isEditMode) {
      setDynamicPrice(0);
      setIsEditMode(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const onFinish = (val) => {
    let slotDetailsObj = IsTransfer && val.slotDetails.map(slot => ({
      noofticket: slot.noofticket,
      startingTime: moment(slot.slottime).format("HH:mm"),
    }));

    if(IsTransfer == 0){
      let totalDuration = moment.duration(val.endTime.diff(val.startTime));
      let hours = totalDuration.hours();
      let duration = moment(val.duration).hour();

      if (duration > hours) {
        message.error(
          "Event Duration Must be less then Total difference between Opening and Closing Hours",
          3
        );
        return;
      }
    }

    let obj = {
      tourId: val.tourName,
      tourOptionId: val.optionName,
      fromDate: moment(val.fromDate).format("DD-MM-YYYY"),
      toDate: moment(val.toDate).format("DD-MM-YYYY"),
      availableDate: "",
      startTime: moment(val.startTime).format(timeFormat),
      endTime: moment(val.endTime).format(timeFormat),
      duration: moment(val.duration).format(timeFormat),
      ticketCount: val.ticketCount ?? 0,
      isDynamicPrice: val.isDynamicPrice,
      adultPrice: val.isDynamicPrice === 1 ? val.adultPrice : 0,
      childPrice: val.isDynamicPrice === 1 ? val.childPrice : 0,
      infantPrice: val.isDynamicPrice === 1 ? val.infantPrice : 0,
      status: 1,
      createdBy: 1,
      createdDate: new Date(),
      modifiedBy: 0,
      modifiedDate: new Date(),
      slotDetails: slotDetailsObj,
      IsSymetricTimeslot: IsTransfer
    };

    isEditMode ? updateActivitiesTimeSlot(obj) : getSaveActivitiesTimeSlot(obj);
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  /** API CALLS */

  const updateTimeSlotByTourOptionId = (data) => {
    APIClient.post(`extranet/updateTimeSlotByTourOptionId`, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Activities TimeSlot Updated SuccessFully", 3);
          setLoding(false)
          getActivitiesTimeSlot();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  const updateActivitiesTimeSlot = (data) => {
    APIClient.put(`​extranet​/activitiesTimeSlot`, {}, data)
      .then((response) => {
        if (response.status == 200) {
          setDynamicPrice(0);
          form.resetFields();
          setModalVisible(false);
          message.success("Activities TimeSlot Updated SuccessFully", 3);
          getActivitiesTimeSlot();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  const getSaveActivitiesTimeSlot = (data) => {
    APIClient.post("extranet/activitiesTimeSlot", data)
      .then((resp) => {
        if (resp.status == 200) {
          setDynamicPrice(0);
          setIsTransfer(0);
          setTicketNumber(0);
          form.resetFields();
          message.success("Activities TimeSlot Saved SuccessFully", 3);
          setModalVisible(false);
          getActivitiesTimeSlot();
        } else if (resp.status == 400) {
          message.error("Failed to Save Activities Rates", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  const getAllActivities = () => {
    APIClient.get("extranet/getAllActivities").then((resp) => {
      if (resp.status == 200) {
        let data = resp.data.map((item) => {
          return {
            tourId: item.tourId,
            tourName: item.tourName,
          };
        });
        setActivitiesList(data);
      }
    });
  };

  const getActivitiesOptions = () => {
    APIClient.get(`extranet/activitiesOptions/${tourId}`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((option, index) => {
            return {
              tourOptionId: option.tourOptionId,
              optionName: option.optionName,
            };
          });
          setActivitiesOptionList(data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Data", 3);
        }
      })
      .catch((error) => { });
  };

  const getActivitiesTimeSlot = () => {
    APIClient.get("extranet/activitiesTimeSlot")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((timeSlot, index) => {
            return {
              SNo: index + 1,
              ...timeSlot,
            };
          });
          const uniqueData = [...new Map(data.map((timeSlot) => [timeSlot.tourOptionId, timeSlot])).values()];
          setActivitiesTimeSlotList(uniqueData);
          setLoding(false);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Data", 3);
          setLoding(false);
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getAllActivities();
    getActivitiesTimeSlot();
  }, []);

  useEffect(() => {
    if (tourId != null) {
      getActivitiesOptions();
    }
  }, [tourId]);

  const validateMessages = {
    required: "",
  };

  function handleDynamicPrice(e) {
    setDynamicPrice(e.target.value);
  };

  function handleTransfer(e) {
    setIsTransfer(e.target.value);
  };

  let lastIndex = 0;
  const uniquekeys = () => {
    lastIndex++;
    return lastIndex;
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <div className="admin-container">
            <Row>
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>
                            View Actitvity Time Slot{" "}
                            <HelpInfoHelper
                              screenName={"/admin/activitytimeslot"}
                            />
                          </h5>
                          <p>{activitiesTimeSlotList.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        {/* <img src={excel} alt="excel" onClick={handleExcel}/> */}
                        {/* <img src={pdf} alt="pdf" /> */}
                        {/* <img src={word} alt="word" /> */}
                        {/*  <img src={exchange} alt="exchange" /> */}
                        <p className="add-icon" onClick={add}>
                          <PlusOutlined />
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div>
                      {loding ? (
                        <Spin
                          size="large"
                          tip="Loading..."
                          style={{ width: "100%" }}
                        />
                      ) : activitiesTimeSlotList.length > 0 ? (
                        <Table
                          className="table-scroll-none"
                          bordered
                          dataSource={activitiesTimeSlotList}
                          rowKey={uniquekeys}
                          columns={columnActivitiesTimeSlotList}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                        />
                      ) : (
                        <b>No Results Found!</b>
                      )}
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Actitvity Time Slot</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button key="add" type="primary" onClick={() => form.submit()}>
                {isEditMode ? "Update" : "Add"}
              </Button>
              <Button
                type="primary"
                danger
                className="cancel-btn"
                onClick={reset}
              >
                Clear
              </Button>
            </div>,
          ]}
          width={"1000px"}
        >
          <Form
            layout="vertical"
            name="basic"
            form={form}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={16}>
              <Col md={6} xs={24}>
                <Form.Item
                  label="Activities"
                  name="tourName"
                  rules={[{ required: true }]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Select Activities"
                    onSelect={(e) => selectTours(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {activitiesList.map((item, index) => (
                      <Option key={index} value={item.tourId}>
                        {`${item.tourName}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={6} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Activities Option"
                  name="optionName"
                  rules={[{ required: true }]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    showSearch
                    //disabled={!isOptionSelectable}

                    placeholder="Select Activities"
                    onSelect={(e) => selectTours(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {activitiesOptionList.map((item, index) => (
                      <Option key={index} value={item.tourOptionId}>
                        {`${item.optionName}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={6} xs={24} className="tour-rates-activity">
                <Form.Item label="From Date" name="fromDate">
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={disabledOriginDate}
                    format={dateFormat}
                    placeholder="From Date"
                    maxDate={new Date()}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24} className="tour-rates-activity">
                <Form.Item label="To Date" name="toDate">
                  <DatePicker
                    disabledDate={disabledOriginDate}
                    style={{ width: "100%" }}
                    format={dateFormat}
                    placeholder="To Date"
                    maxDate={new Date()}
                  />
                </Form.Item>
              </Col>

              {IsTransfer === 0 && (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Opening Hours"
                      name="startTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker style={{ width: "100%" }} format={timeFormat} />
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Closing Hours"
                      name="endTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker style={{ width: "100%" }} format={timeFormat} />
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Event Duration"
                      name="duration"
                      rules={[{ required: true }]}
                    >
                      <TimePicker style={{ width: "100%" }} format={timeFormat} />
                    </Form.Item>
                  </Col>

              <Col md={6} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="No of ticket available "
                  name="ticketCount"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              </>
              )}

              <Col md={4} xs={24}>
                <Form.Item
                  label="Is Dynamic Price"
                  name="isDynamicPrice"
                  rules={[{ required: true }]}
                >

                  <Radio.Group onChange={handleDynamicPrice}>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {dynamicPrice === 1 ? (
                <>
                  <Col md={4} xs={24}>
                    <Form.Item
                      label="Adult Price"
                      name="adultPrice"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24}>
                    <Form.Item
                      label="Child Price"
                      name="childPrice"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24}>
                    <Form.Item
                      label="Infant Price"
                      name="infantPrice"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              <Col md={4} xs={24}>
                <Form.Item
                  label="Non-Symatric"
                  name="isSymatric"
                  rules={[{ required: true }]}
                >

                  <Radio.Group onChange={handleTransfer}>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {IsTransfer == 1  && (
              <Col md={6} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="no of Slot"
                  name="noofslot"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="Select No of Slot"
                    onSelect={(e) => setTicketNumber(e)}
                  >
                    {[...new Array(20)].map((_, i) => (
                      <Option key={++i} value={++i}>
                        {`${i}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              )}
            </Row>
            {/* <Form.Item name="slotDetails">
              {[...Array(ticketNumber)].map((_, index) => (
                <Row key={index}>
                  <Col md={8} xs={24}>
                    <Form.Item label="Slot Time" name={`slottime[${index}]`} rules={[{ required: true }]}>
                      <TimePicker.RangePicker format={"HH:mm"}/>
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="Number of Tickets" name={`noofticket[${index}]`} rules={[{ required: true }]}>
                      <Input placeholder="Number of Tickets"/>
                    </Form.Item>
                  </Col>
              </Row>
                ))}
            </Form.Item> */}
            {IsTransfer == 1 && (
            <Form.Item name="slotDetails">
              {Array.from({ length: ticketNumber }, (_, index) => (
                <Row key={index}>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Slot Time"
                      name={['slotDetails', index, 'slottime']}
                      rules={[{ required: true }]}
                    >
                      <TimePicker format={"HH:mm"} />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Number of Tickets"
                      name={['slotDetails', index, 'noofticket']}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Number of Tickets" />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Activitytimeslot;
