import { Card, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import ReactHtmlParser from "react-html-parser";

import {
  formatFlightTime,
  getFlightPrice,
  calculateDuration,
} from "./flightHelper.js";
import { getFlightTicketStatus } from "../AllTicketStatus";
import "../NewTicket.scss";
import { SafetyCertificateOutlined } from "@ant-design/icons";

const TicketFlight = ({ ticketData, fareRulesResp, cmsFareRules }) => {
  let uidateFormat = "DD-MMM-YYYY";
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    RefundAmount,
    grandTotal,
    insuranceTotal,
  } = getFlightPrice(ticketData);

  return (
    <>
      <span className="tic-gredient-f"></span>

      <div className="cms-pages-width ourteam-bg">
        <Row>
          <Col md={24} xs={24}>
          <Col md={24} xs={24} className="tktdate">
            <p className="tic-city-name-d2">
              {ticketData.tripType === "roundTrip"
                ? "Onward Details"
                : `${ticketData.source} To ${ticketData.destination}`}
            </p>
            {ticketData?.BookingStatus === "HOLD"  &&
            <p className="tic-city-name-d2">
           
            </p>
}
            </Col>
  

              
            <Card className="card-tic-f3">
              <Row gutter={[16, 8]}>
                <Col md={10} sm={24} xs={24}>
                  <Row gutter={[16, 8]}>
                    <Col md={10} sm={10} xs={24}>
                      <div className="city-f3">
                        <p>{ticketData.source}</p>
                        <span>
                          {`${ticketData.oneWaySegment[0].flightCode} -
                            ${ticketData.oneWaySegment[0].flightNumber}`}
                        </span>
                      </div>
                    </Col>
                    <Col md={4} sm={4} xs={24}>
                      <div className="city-f3">
                        <p>
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </Col>
                    <Col md={10} sm={10} xs={24}>
                      <div className="city-f3">
                        <p>{ticketData.destination}</p>
                        {/* <span>
                          {ticketData.tripType == "roundTrip"
                            ? "RoundTrip"
                            : "Oneway"}
                        </span> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={14} sm={24} xs={24}>
                  <Row>
                    <Col md={7} sm={8} xs={24}>
                      <div className="city-f4">
                        <p>
                          {moment(ticketData.journeyDate).format(uidateFormat)}
                        </p>
                        <span>Journey Date</span>
                      </div>
                    </Col>
                    <Col md={17} sm={16} xs={24}>
                      <div className="city-f4">
                        <p className="word-break break-pnr">
                          {ticketData.tripType === "roundTrip"
                            ? ticketData.pnr.includes("~")
                              ? ticketData.pnr.split("~")[0]
                              : ticketData.pnr
                            : ticketData.pnr}
                        </p>
                        <span>PNR/Booking No</span>
                      </div>
                    </Col>
                

                  </Row>
                </Col>
              </Row>
              <div className="border-hr"></div>
              <Row className="row-top-f4">
                <Col md={6} xs={24} className="city-f4">
                  <p>{ticketData.referenceNumber}</p>
                  <span>Ticket Ref Number</span>
                </Col>
             
                <Col md={4} xs={24} className="city-f4">
                  {getFlightTicketStatus(ticketData.oneWaySegment[0].bookingStatus)}

                  <span>Status</span>
                </Col>
                <Col md={6} xs={24} className="city-f4">
                  <p>
                    {ticketData.bookingDate
                      ? moment(ticketData.bookingDate).format(uidateFormat)
                      : ""}
                  </p>
                  <span>Booking Date</span>
                </Col>
                {/* <Col md={5} xs={24} className="city-f4">
                  <p>Paid</p>
                  <span>Payment Status</span>
                </Col> */}
                <Col md={5} xs={24} className="city-f4">
                  <p>{ticketData.oneWaySegment[0].bookingStatus === 10 ? "Due" : "Paid"}</p>
                  <span>Payment Status</span>
                </Col>
              </Row>
            </Card>

            <Card className="card-tic-f1">
              <Row className="info-flight-t22">
                <Col md={24} xs={24} className="city-tt-f4 tktdate">
                  <Col>  <p>Travel Information</p>
                  <span>
                    Here are the details of your flight from{" "}
                    {ticketData.oneWaySegment[0].origin} to{" "}
                    {
                      ticketData.oneWaySegment[
                        ticketData.oneWaySegment.length - 1
                      ].destination
                    }{" "}
                    on {moment(ticketData.journeyDate).format(uidateFormat)}
                  </span></Col>
                  {ticketData.oneWaySegment[0].bookingStatus==10 &&  <Col className="status-ticket"> {moment(ticketData.tktTimeLimit).format("DD-MM-YYYY")} | IST {moment(ticketData.tktTimeLimit).format("HH:mm")} Hours                
                 <span  className="status-ticket">Last Ticketing Date</span></Col>}
                
                </Col>
              </Row>
              {ticketData.oneWaySegment.length > 0
                ? ticketData.oneWaySegment.map((flightSegment, key) => (
                    <Row key={key + "seg"}>
                      <Col md={6} xs={24} className="city-tt-f5">
                        <div className="logo-f3-text">
                          <div className="sg-logo-flight">
                            <p>
                              {" "}
                              {`${ticketData.operator}  (${flightSegment.flightCode} -
                            ${flightSegment.flightNumber})`}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={4} xs={24} className="city-tt-f5">
                        <div className="logo-f3-text">
                          <div className="sg-logo-flight">
                            <p>
                            {moment(
                                flightSegment.departureDateTime
                              ).format(uidateFormat)}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} xs={24} className="city-tt-f4">
                        <div className="city-from-d3">
                          <div className="city-f55">
                            <p>{flightSegment.origin}</p>
                            <span>
                              {formatFlightTime(
                                flightSegment.departureDateTime
                              )}
                            </span>
                          </div>
                          <div className="city-f55">
                            <p className="dotted-line-flight"></p>
                            <span>
                              {calculateDuration(
                                flightSegment.departureDateTime,
                                flightSegment.arrivalDateTime
                              )}
                            </span>
                          </div>
                          <div className="city-f55">
                            <p>{flightSegment.destination}</p>
                            <span>
                              {formatFlightTime(flightSegment.arrivalDateTime)}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))
                : null}

              <Row className="baggagge-bottom-sec">
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <p className="baggagge-icon-4">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                    </p>
                    <p className="info-line-bag">Baggage Information</p>
                  </div>
                </Col>

                <Col md={4} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <p className="baggagge-icon-5">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                    </p>

                    <div className="allowance-baggage">
                      <p>Check-in</p>
                      <span>
                        {ticketData.oneWayBaggageInfo.length > 0
                          ? ticketData.oneWayBaggageInfo[0].BaggageInfo
                          : null}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={8} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <p className="baggagge-icon-5">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                    </p>

                    <div className="allowance-baggage">
                      <p>Hand Baggage</p>
                      <span>
                        {ticketData.oneWayBaggageInfo.length > 0
                          ? ticketData.oneWayBaggageInfo[0].cabinBaggageInfo
                          : null}
                      </span>
                    </div>
                    
                  </div>
                </Col>
                <Col md={5} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <p className="baggagge-icon-5">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                    </p>

                    <div className="allowance-baggage">
                      <p>FareRule</p>
                      <span>
                        {ticketData.oneWayBaggageInfo.length > 0
                          ? ticketData.oneWayBaggageInfo[0].cabinBaggageInfo
                          : null}
                      </span>
                    </div>
                    
                  </div>
                </Col>
              </Row>
            </Card>
            {ticketData.returnSegment.length > 0 &&
            ticketData.tripType === "roundTrip" ? (
              <>
                <p className="tic-city-name-d2" style={{ color: "#000" }}>
                  Return Details
                </p>
                <Card className="card-tic-f3">
                  <Row gutter={[16, 8]}>
                    <Col md={10} sm={24} xs={24}>
                      <Row gutter={[16, 8]}>
                        <Col md={10} sm={10} xs={24}>
                          <div className="city-f3">
                            <p>{ticketData.destination}</p>
                            <span>
                              {`${ticketData.returnSegment[0].flightCode} -
                            ${ticketData.returnSegment[0].flightNumber}`}
                            </span>
                          </div>
                        </Col>
                        <Col md={4} sm={4} xs={24}>
                          <div className="city-f3">
                            <p>
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </p>
                          </div>
                        </Col>
                        <Col md={10} sm={10} xs={24}>
                          <div className="city-f3">
                            <p>{ticketData.source}</p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={14} sm={24} xs={24}>
                      <Row>
                        <Col md={7} sm={8} xs={24}>
                          <div className="city-f4">
                            <p>
                              {moment(
                                ticketData.returnSegment[0].departureDateTime
                              ).format(uidateFormat)}
                            </p>
                            <span>Journey Date</span>
                          </div>
                        </Col>
                        <Col md={17} sm={16} xs={24}>
                          <div className="city-f4">
                            <p className="word-break break-pnr">
                              {ticketData.pnr.includes("~")
                                ? ticketData.pnr.split("~")[1]
                                : ticketData.pnr}
                            </p>
                            <span>PNR/Booking No</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="border-hr"></div>
                  <Row className="row-top-f4">
                    <Col md={6} xs={24} className="city-f4">
                      <p>{ticketData.referenceNumber}</p>
                      <span>Ticket Ref Number</span>
                    </Col>
                    <Col md={4} xs={24} className="city-f4">
                    {getFlightTicketStatus(ticketData.returnSegment[0].bookingStatus)}

                      <span>Status</span>
                    </Col>
                    <Col md={6} xs={24} className="city-f4">
                      <p>
                        {ticketData.bookingDate
                          ? moment(ticketData.bookingDate).format(uidateFormat)
                          : ""}
                      </p>
                      <span>Booking Date</span>
                    </Col>
                    <Col md={5} xs={24} className="city-f4">
                      <p>Paid</p>
                      <span>Payment Status</span>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-tic-f1">
                  <Row className="info-flight-t22">
                    <Col md={24} xs={24} className="city-tt-f4 tktdate">
                      <Col><p>Travel Information</p>
                      <span>
                        Here are the details of your flight from{" "}
                        {ticketData.returnSegment[0].origin} to{" "}
                        {
                          ticketData.returnSegment[
                            ticketData.returnSegment.length - 1
                          ].destination
                        }{" "}
                        on{" "}
                        {moment(
                          ticketData.returnSegment[0].departureDateTime
                        ).format(uidateFormat)}
                      </span></Col>
                {ticketData.returnSegment[0].bookingStatus==10 &&
                      <Col className="status-ticket"> {ticketData?.returntktTimeLimit}
                 <span  className="status-ticket">Last Ticketing Date</span>
                    </Col>}
                    </Col>
                  </Row>
                  {ticketData.returnSegment.length > 0
                    ? ticketData.returnSegment.map((flightSegment, i) => (
                        <Row key={i + "fseg"}>
                          <Col md={8} xs={24} className="city-tt-f5">
                            <div className="logo-f3-text">
                              <div className="sg-logo-flight">
                                <p>
                                  {" "}
                                  {`${ticketData?.returnOperator}  (${flightSegment.flightCode} -
                            ${flightSegment.flightNumber})`}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={16} xs={24} className="city-tt-f4">
                            <div className="city-from-d3">
                              <div className="city-f55">
                                <p>{flightSegment.origin}</p>
                                <span>
                                  {formatFlightTime(
                                    flightSegment.departureDateTime
                                  )}
                                </span>
                              </div>
                              <div className="city-f55">
                                <p className="dotted-line-flight"></p>
                                <span>
                                  {calculateDuration(
                                    flightSegment.departureDateTime,
                                    flightSegment.arrivalDateTime
                                  )}
                                </span>
                              </div>
                              <div className="city-f55">
                                <p>{flightSegment.destination}</p>
                                <span>
                                  {formatFlightTime(
                                    flightSegment.arrivalDateTime
                                  )}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))
                    : null}

                  <Row className="baggagge-bottom-sec">
                    <Col md={6} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <p className="baggagge-icon-4">
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                        </p>
                        <p className="info-line-bag">Baggage Information</p>
                      </div>
                    </Col>

                    <Col md={4} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <p className="baggagge-icon-5">
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                        </p>

                        <div className="allowance-baggage">
                          <p>Check-in</p>
                          <span>
                            {ticketData.returnBaggageInfo.length > 0
                              ? ticketData.returnBaggageInfo[0].BaggageInfo
                              : null}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col md={8} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <p className="baggagge-icon-5">
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                        </p>

                        <div className="allowance-baggage">
                          <p>Hand Baggage</p>
                          <span>
                            {ticketData.returnBaggageInfo.length > 0
                              ? ticketData.returnBaggageInfo[0].cabinBaggageInfo
                              : null}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </>
            ) : null}
            <Card className="card-tic-f2">
              <Row>
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Contact Details</p>
                  <span>
                    Any Communication by airlines will be sent these details
                  </span>
                </Col>
                {/* <Col md={4} xs={24} className="modify-contact-btn">
<Button className="tic-btn-contact2">Modify</Button>
             </Col> */}
              </Row>

              <Row>
                <Col md={10} xs={24} className="contact-person-d4">
                  <span>Name</span>
                  <p>{ticketData.guestName}</p>
                  <span>Email</span>
                  <p>{ticketData.guestEmaiId}</p>
                </Col>
                <Col md={14} xs={24} className="contact-person-d4">
                  <span>Mobile Number</span>
                  <p>{ticketData.guestMobileNo}</p>
                  <span>Home Address</span>
                  <p>
                    {ticketData.passengers[0].address},{" "}
                    {ticketData.passengers[0].passengerNationality}
                  </p>
                </Col>
              </Row>
            </Card>
            <Card className="card-tic-f2">
              <Row>
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Passengers Details</p>
                </Col>
              </Row>
              {ticketData.passengers.length > 0
                ? ticketData.passengers.map((item, key) => (
                    <Row key={key + "pax1"}>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Name</span>
                        <p>
                          {item.firstName} {item.lastName}
                        </p>
                      </Col>
                      {item.dob ? (
                        <Col md={6} xs={24} className="contact-person-d4">
                          <span>DOB</span>
                          <p>{moment(item.dob).format(uidateFormat)}</p>
                        </Col>
                      ) : null}

                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Passenger Type</span>
                        <p>
                          {item.paxType === "ADT"
                            ? "Adult"
                            : item.paxType === "CHD"
                            ? "Child"
                            : "Infant"}
                        </p>
                      </Col>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>{ticketData.tripType === "roundTrip"?"Onward Ticket No.":"Ticket No."}</span>
                        <p>
                        {item.tickets[0]?.ticketNumber}
                        </p>
                      </Col>
                      {item.returnTickets.length>0 && item.returnTickets[0]?.returnTicketNumber ? (<Col md={6} xs={24} className="contact-person-d4">
                        <span>Return Ticket No.</span>
                        <p>
                        {item?.returnTickets.length>0 && item.returnTickets[0]?.ticketNumber
                                  ? item.returnTickets[0]?.ticketNumber
                                  : null}
                        </p>
                      </Col>):null}
                    </Row>
                  ))
                : null}
            </Card>
            {ticketData?.insuranceRequired === 1 &&
            ticketData.insuranceData &&
            ticketData?.insuranceData?.serviceType === 1 ? (
              <Card className="insurance-section card-tic-f2">
                <h5 style={{ fontSize: "16px" }}>THE SMART INSURANCE COVER</h5>
                <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                <Row gutter={[16, 16]} align="stretch" className="mt-4">
                  <Col md={8} sm={24} xs={24}>
                    <div className="insurance-box">
                      {ticketData?.insuranceData.description_box_1}
                    </div>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <div className="insurance-box">
                      {ticketData?.insuranceData.description_box_2}
                    </div>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <div className="insurance-box">
                      {ticketData?.insuranceData.description_box_3}
                    </div>
                  </Col>
                </Row>

                <div className="insurance-coverage">
                  <SafetyCertificateOutlined />
                  <span>
                    Insurance Coverage Amount :{" "}
                    {ticketData?.insuranceData.insuranceCoverage}
                  </span>
                </div>
              </Card>
            ) : null}
            <Card className="card-tic-f2">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Fare Details</p>
                </Col>
              </Row>

              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Base Fare</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {baseAmount}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Surge and Taxes:YQ:</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {taxAmount}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>{ticketData?.RoleId == 4 ? "Conveneience Fee" : "Services Charge"} </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {ticketData?.RoleId == 4 ? convienenceFee : ticketData?.markup}</p>
                </Col>
              </Row>
              {ticketData?.insuranceRequired === 1 &&
              ticketData.insuranceData ? (
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Insurance Amount</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <p>INR {insuranceTotal}</p>
                  </Col>
                </Row>
              ) : null}
              {/* <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Discount</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {ticketData?.commission}</p>
                </Col>
              </Row> */}

              {/* Add TDS By Karthik Refence Ravi 27/06/2023 */}
              {/* <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>TDS</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  Refences by Ravi and nitin sir 27/06/2023
                  <p>INR {((ticketData?.commission*5)/100).toFixed(2)}</p>
                </Col>
              </Row> */}
              {/* Add TDS By Karthik Refence Ravi 27/06/2023 */}

              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Total</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> INR {grandTotal}</p>
                </Col>
              </Row>
              {ticketData.BookingStatus !== "CONFIRMED" ? (
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>RefundAmount</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <span>INR {RefundAmount}</span>
                  </Col>
                </Row>
              ) : null}
            </Card>
            <Card className="card-tic-f2">
              <Row>
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>{"Terms & Conditions"}</p>
                </Col>
              </Row>

              <Row>
                <Col md={24} xs={24} className="contact-person-d4">
                  {cmsFareRules?.cancelPolicyDescription ? (
                    <div className="termCon_wrapper">
                      <div className="description">
                        <p className="title">Cancel Policy</p>
                        {ReactHtmlParser(cmsFareRules?.cancelPolicyDescription)}
                        {cmsFareRules?.covidRulesDescription ? (
                          <>
                            <p className="title">Covid Policy</p>

                            {ReactHtmlParser(
                              cmsFareRules?.covidRulesDescription
                            )}
                          </>
                        ) : null}
                        {cmsFareRules?.reschedulePolicyDescription ? (
                          <>
                            <p className="title">Reschedule Policy</p>

                            {ReactHtmlParser(
                              cmsFareRules?.reschedulePolicyDescription
                            )}
                          </>
                        ) : null}

                        {cmsFareRules?.otherRulesDescription ? (
                          <>
                            <p className="title">Other Policy</p>

                            {ReactHtmlParser(
                              cmsFareRules?.otherRulesDescription
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <>
                      {!fareRulesResp ? (
                        <p>...</p>
                      ) : (
                        <div>
                          <pre>
                            {ReactHtmlParser(
                              fareRulesResp.fareRules[0].ruleDetails
                            )}
                          </pre>
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TicketFlight;
