import React from "react";
import * as ReactBootstrap from "react-bootstrap";

const PassengerDetails = (props) => {
  return (
    <div className="passenger-details-container">
      <div className="passenger-details-card">
        {props.passengersInfo.length > 0 ? (
          <React.Fragment>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th>Room</th> */}
                    <th>Title</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Pan Number</th>
                  </tr>
                </thead>
                <tbody>
                  {props.passengersInfo.map((paxInRoomObj, roomIndex) => (
                    paxInRoomObj.paxInfoList.map((paxObj, index) => (
                      <React.Fragment>
                        {index == 0 && (
                          <h6><b>Room {roomIndex + 1}</b></h6>
                        )}
                        <tr key={`${roomIndex}-${index}`}>
                        <td>{paxObj.title}</td>
                        <td>{paxObj.firstName} {paxObj.lastName}</td>
                        <td>{paxObj.guestType === "Adult" ? "Adult" : "Child"}</td>
                        <td>{paxObj.pan}</td>
                      </tr>
                      </React.Fragment>
                    ))
                  ))}
                </tbody>
              </table>
            </div>

          </React.Fragment>
        ) : (
          <p>No Passengers Info available</p>
        )}
      </div>
    </div>
  );
};
export default PassengerDetails;
