import React, { useContext, useState, useEffect,useRef } from "react";

import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import {
  Row,
  Card,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Skeleton,
  message,
  Radio,
  Checkbox,
  Result,
  Button
} from "antd";

import HotelFairBox from "./HotelFairBox";
import { GlobalStatesContext } from "../../../common/providers";

import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import { getPassengerData } from "../../../helpers/PassegerData";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import HotelBookPayCard from "./HotelBookPayCard";
import CountryList from "../../../common/CountryList";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import ApiClient from "../../../helpers/ApiClient";
import "./HotelCheckout.scss";
import PanVerification from "./PanVerification";
import RoomDetailsCard from "../HotelDet/RoomDetailsCard";

const { Option } = Select;

const HotelCheckout = () => {
  const { setHotelCheckOutData } = useHotelContext();
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const {
    state: {
      otherData: { insuranceData, selectedInsuranceData,PanVerificationDetails },
    },

    setSelectedInsuranceData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  const [contact_form] = Form.useForm();
  const [guestDetailsForm] = Form.useForm();
  const [ids, setIds] = useState({
    traceId: null,
    repriceId: null,
  });
  const [loading, setLoading] = useState(true);
  const [hotelPriceData, setHotelPriceData] = useState({});
  const [SamePanNumber, setSamePanNumber] = useState(false);
  const [panVerificationDetail, setPanVerificationDetails] = useState([]);
  const [isPanRequired, setIsPanRequired] = useState(false);

  const [hotelSearchData, setHotelSearchData] = useState({});

  const [roomGuestInfo, setRoomGuestInfo] = useState([]);

  const handlePaxField = (val, roomIndex, paxIndex, key) => {
    let temp = [...roomGuestInfo];

    if (paxIndex === 0) {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = true;
    } else {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = false;
    }

    temp[roomIndex].paxInfoList[paxIndex][key] = val;

    setRoomGuestInfo(temp);
  };

  const [insuranceRequired, setInsuranceRequired] = useState(-1);

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            phoneNo: data.Mobile,
            email: data.Email,
            // areaCode: data?.DailingCode ? `+${data?.DailingCode}` : "",
            addressLine1: data?.Address1 ? data?.Address1 : "",
            city: data?.city ? data?.city : "",
            state: data?.state ? data?.state : "",
          });
        }
      });
    }
  };


  // Function to set initial values based on panVerificationDetail
  const setInitialFormValues = () => {
    const initialValues = {};

    // Build your initial values object here, for example:
    panVerificationDetail.forEach(detail => {
      const firstNameField = `firstname_${detail.roomIndex}_${detail.paxIndex}`;
      const lastNameField = `lastname_${detail.roomIndex}_${detail.paxIndex}`;
      const prefixNameField = `Title_${detail.roomIndex}_${detail.prefix}`;

      const firstNameValue = detail.first_name.trim();
      const lastNameValue = detail.last_name.trim();
      const prefixValue = detail?.prefix;

      // if(prefixValue == 'Mr' || prefixValue == 'Mrs'){
      //   initialValues[prefixNameField] = prefixValue;
      // }
      let temp = [...roomGuestInfo];
      if(Array.isArray(temp) && temp.length > 0){
      if (detail.paxIndex === 0) {
        temp[detail.roomIndex].paxInfoList[detail.paxIndex]["leadGuest"] = true;
      } else {
        temp[detail.roomIndex].paxInfoList[detail.paxIndex]["leadGuest"] = false;
      }
    }
  
     
  
   
    
      if (!firstNameValue && lastNameValue) {
        // First name is blank, but last name has a value
        initialValues[firstNameField] = lastNameValue;
        initialValues[lastNameField] = lastNameValue;
      } else if (firstNameValue && !lastNameValue) {
        // Last name is blank, but first name has a value
        initialValues[firstNameField] = firstNameValue;
        initialValues[lastNameField] = firstNameValue;
      } else {
        // Both first name and last name have values
        initialValues[firstNameField] = firstNameValue;
        initialValues[lastNameField] = lastNameValue;
      }
      if(Array.isArray(temp) && temp.length > 0){
      temp[detail.roomIndex].paxInfoList[detail.paxIndex]["firstName"] = initialValues[firstNameField];
      temp[detail.roomIndex].paxInfoList[detail.paxIndex]["lastName"] = initialValues[lastNameField];
      setRoomGuestInfo(temp);
    }
    });

    // Set the initial values on the form
    guestDetailsForm.setFieldsValue(initialValues);
  };

  const prevPanVerificationDetails = useRef();
  useEffect(() => {

    if (PanVerificationDetails &&
      typeof PanVerificationDetails === 'object' &&
      (!('isloading' in PanVerificationDetails) || PanVerificationDetails?.current?.isLoading === false)) {

      if (prevPanVerificationDetails.current !== PanVerificationDetails) {
        prevPanVerificationDetails.current = PanVerificationDetails;
        const { Id, first_name, last_name, pan_number, prefix
        } = PanVerificationDetails[0];
        setPanVerificationDetails(prevDetails => [...prevDetails, {
          Id, first_name, last_name, pan_number, prefix, paxIndex: PanVerificationDetails.paxIndex,
          roomIndex: PanVerificationDetails.roomIndex
        }]);
      }
    }

  }, [PanVerificationDetails]);
  useEffect(() => {
    if (panVerificationDetail.length > 0) {
      setInitialFormValues();
    }
  }, [panVerificationDetail]);
  useEffect(() => {
    RemovePromoConvFee();
    fetchHotelPrice();
    loadpassengerData();
    setPanVerificationDetails([]);
  }, []);
  const HotelGuestReqFields = (paxObj, guestRequiredFields) => {
    guestRequiredFields.map((paxReqFieldsObj) => {
      Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
        if (paxReqFieldsObj[paxReqKeys] === true) {
          paxObj[paxReqKeys] = "";
        }
      });
    });
    return paxObj;
  };

  const fetchHotelPrice = () => {
    const hotelParams = queryString.parse(window.location.search);
    setIds({
      traceId: hotelParams.traceId,
      repriceId: null,
    });

    setLoading(true);
    let Req = {
      ...hotelParams,
      roomPlan: JSON.parse(hotelParams.roomPlan),
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
    };
    setHotelPriceData({});
    setHotelSearchData({});
    setRoomGuestInfo([]);
    ApiClient.post("hotels-v2/hotelprice", Req)
      .then((res) => res)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errors.length === 0) {
            const { hotels, purchaseType, repriceId, request, traceId } =
              res.data;
            if (
              Object.keys(hotels).length > 0 &&
              Object.keys(request).length > 0
            ) {
              setHotelPriceData({
                ...hotels,
                purchaseType,
                traceId,
                repriceId,
              });
              setHotelSearchData(request);
              setHotelCheckOutData({});
              const roomInfoArr = [];

              let roomsInfo = request.roomGuests;

              roomsInfo.map((room, roomIndex) => {
                const paxListArr = [];

                [...Array(room.noOfAdults)].map(() => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mr.",
                    guestType: "Adult",
                    guestInRoom: roomIndex + 1,
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });
                [...Array(room.noOfChilds)].map((_, index) => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mstr",
                    guestType: "Child",
                    guestInRoom: roomIndex + 1,
                    age: parseInt(room.childAge[index]),
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });

                roomInfoArr.push({ paxInfoList: paxListArr });
              });

              let { pan } = hotels.guestRequiredFields[0];
              setIsPanRequired(pan)

              setRoomGuestInfo(...roomGuestInfo, roomInfoArr);
            }
          } else {
            res.data.errors.forEach((err) => {
              if (err.errorCode === "SOLDOUT") {
                message.error(err.errorDetail, 5);
              }
            });
          }
        } else if (res.status === 500 && res.data?.errors?.length > 0) {
          res.data.errors.forEach((err) => {
            if (err.errorCode === "SOLDOUT") {
              message.error(err.errorDetail, 5);
            }
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };

  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((val) => {
        guestDetailsForm
          .validateFields()
          .then((passegersData) => {
            let countryName = "";
            if (val.countryCode) {
              countryName = CountryList.filter(
                (item) => item.code === val.countryCode
              );
              countryName = countryName[0].name;
            }

            let data = {
              hotelPriceData,
              hotelSearchData,
              addressInfo: {
                addressLine1: val.addressLine1,
                addressLine2: val.addressLine1,
                cellCountryCode: val.areaCode,
                countryCode: val.countryCode,
                areaCode: val.areaCode,
                phoneNo: val.phoneNo,
                email: val.email,
                city: val.city,
                state: val.state,
                country: countryName,
                zipCode: val.zipCode,
              },
              guests: roomGuestInfo,
              insuranceRequired: passegersData?.insuranceRequired === 1 ? 1 : 0,
              insuranceData: selectedInsuranceData,
              samePan: SamePanNumber
            };
            // console.log("checkout data", data);
            setHotelCheckOutData(data);

            // add pan number for all pax code by karthik 12-Jan-2024
            if (SamePanNumber) {
              roomGuestInfo.map((item) => {
                item.paxInfoList.forEach((items) => {
                  items.pan = panVerificationDetail[0].pan_number;
                })
              })
            }else{
              panVerificationDetail.map((item,i) => {
                let data = roomGuestInfo[i];
                data.paxInfoList.forEach((items) => {
                  items.pan = panVerificationDetail[i].pan_number;
                })
              })
            }

            history.push("/hotels/hotel-review");
          })
          .catch((e) => {
            if (e?.errorFields && e?.errorFields.length > 0)
              guestDetailsForm.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  // const getAdultChildCount = (paxInfo) => {
  //   if (paxInfo.length > 0) {
  //     let count = paxInfo.reduce((p, c) => {
  //       let type = c.guestType;
  //       if (!p.hasOwnProperty(type)) {
  //         p[type] = 0;
  //       }
  //       p[type]++;
  //       return p;
  //     }, {});

  //     return `Adult(s) ${count.Adult ? count.Adult : " "} ${
  //       count.Child ? ", Children " + count.Child : " "
  //     }`;
  //   }
  // };

  const gotoHotelDetail = (hotelCode) => {
    let queryObj = {
      hotelId: hotelCode,
      traceId: ids.traceId,
    };
    const query = queryString.stringify(queryObj);
    history.push(`/hotels/hotel-details?${query}`);
  };

  const displayCurrentPan = (panRecord, roomIndex) => {
    let currentPan, allPanRecord;
    if(panRecord && panRecord.length > 0) {
      allPanRecord = panRecord?.filter(x=>x.roomIndex==roomIndex);
      return allPanRecord[allPanRecord.length - 1]?.pan_number;
    }else{
      return '';
    }
  }

  const handleBackBtn = () => {
    window.history.back()
  }

  return (
    <div className="hotel-checkout-wrapper">
      <div className="form-header">
        <div className="form-header-container ">
          <div className="form-header-text">
            <h2>Fill out the form below and book your stay now!</h2>
          </div>
        </div>
      </div>
      {loading ? (
        <Card bordered={false} className="hotel-card-wrapper container">
          <Skeleton active />
        </Card>) :
      Object.keys(hotelPriceData).length > 0 ? (
      <div className="form-body">
        <div className="form-body-container">
          <Row gutter={[16, 16]}>
            <Col md={16} sm={24} xs={24}>
              <div className="hotel-detail-header-wrapper">
                <p className="booking-summary-div">Hotel Details</p>

                {hotelPriceData?.hotelCode && (
                  <p
                    className="pointer_cursor"
                    onClick={() => gotoHotelDetail(hotelPriceData.hotelCode)}
                  >
                    Change Room
                  </p>
                )}
              </div>

              {loading ? (
                <Card bordered={false} className="hotel-card-wrapper">
                  <Skeleton active />
                </Card>
              ) : Object.keys(hotelPriceData).length > 0 ? (
                <React.Fragment>
                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelDetailsBox
                    Ids={ids}
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                  />
                </Card>
                
                {hotelPriceData.rooms.length > 0 && (
                  <React.Fragment>
                  <h5>Room Details</h5>
                  <RoomDetailsCard RoomDetails={hotelPriceData}/>
                </React.Fragment>
                )}
                </React.Fragment>
              ) : null}

              <div className="contact-header">
                <div className="contact-title">
                  <p className="booking-summary-div">Guests Details</p>
                  { roomGuestInfo?.length > 0 && isPanRequired &&
                    <Col md={8} sm={12} >
                      <Checkbox onChange={(e) => setSamePanNumber(e.target.checked)}><strong >Same Pan Card For All Room</strong></Checkbox>
                    </Col>}
                </div>
              </div>

              {loading ? (
                <Card bordered={false} className="hotel-card-wrapper">
                  <Skeleton active />
                </Card>
              ) : roomGuestInfo?.length > 0 ? (
                <Form
                  form={guestDetailsForm}
                  scrollToFirstError={true}
                  layout="vertical"
                >
                  {roomGuestInfo.map((roomsObj, roomIndex) => (
                    <Card
                      bordered={false}
                      className="guest-details-form hotel-card-wrapper"
                      key={roomIndex}
                    >

                      <p className="room-title" style={{ display: "flex", justifyContent: "space-between" }}>Room {1 + roomIndex} 
                      <span style={{ color: "red"}}>{displayCurrentPan(panVerificationDetail, roomIndex)}</span>
                      </p>

                      {roomsObj.paxInfoList.map((pax, paxIndex) => (
                        <div
                          key={roomIndex + "detials" + paxIndex}
                          className="guest-input-wrapper"
                        >
                          <p className="guestsType">
                            {pax.guestType === "Adult" ? "Adult" : "Child"}{" "}

                          </p>

                          <Row gutter={[16, 16]}>
                            <Col md={3} sm={12} xs={24}>
                              {pax.guestType === "Adult" ? (
                                <Form.Item
                                  name={`Title_${roomIndex}_${paxIndex}`}
                                  label="Title"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => {
                                      handlePaxField(
                                        val,
                                        roomIndex,
                                        paxIndex,
                                        "title"
                                      );
                                    }}
                                  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Ms">Ms</Option>
                                    <Option value="Mrs">Mrs</Option>
                                  </Select>
                                </Form.Item>
                              ) : (
                                <Form.Item
                                  name={`Title_${roomIndex}_${paxIndex}`}
                                  label="Title"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => {
                                      handlePaxField(
                                        val,
                                        roomIndex,
                                        paxIndex,
                                        "title"
                                      );
                                    }}
                                  >
                                     <Option value="Mr">Mr</Option>
                                     <Option value="Ms">Ms</Option>
                                    <Option value="Mstr">Mstr</Option>
                                  </Select>
                                </Form.Item>
                              )}
                            </Col>

                            <Col md={6} sm={12} xs={24}>
                              <Form.Item
                                label="First Name"
                                name={`firstname_${roomIndex}_${paxIndex}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}

                              >
                                <Input
                                  onChange={(e) => {
                                    handlePaxField(
                                      e.target.value,
                                      roomIndex,
                                      paxIndex,
                                      "firstName"
                                    );
                                  }}
                                  initialValue={panVerificationDetail && panVerificationDetail.length >= 1 ? panVerificationDetail?.filter(x => x.roomIndex == roomIndex && x.paxIndex == paxIndex)[0]?.pan_number : ''}
                                  disabled={(SamePanNumber == true && roomIndex == 0 && paxIndex == 0) || (SamePanNumber == false && paxIndex == 0) && isPanRequired ? true : false}
                                />
                              </Form.Item>
                            </Col>

                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Last Name"
                                name={`lastname_${roomIndex}_${paxIndex}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                ]}
                              >
                                <Input
                                  onChange={(e) => {
                                    handlePaxField(
                                      e.target.value,
                                      roomIndex,
                                      paxIndex,
                                      "lastName"
                                    );
                                  }}
                                  disabled={(SamePanNumber == true && roomIndex == 0 && paxIndex == 0) || (SamePanNumber == false && paxIndex == 0) && isPanRequired ? true : false}
                                />
                              </Form.Item>
                            </Col>

                            {/* {pax.hasOwnProperty("pan") && SamePanNumber === false && ( */}
                            {/* {paxIndex==0 &&(
                              <Col md={6} sm={12} xs={24}>
                       {roomIndex >=1 &&  SamePanNumber === true && (
                                <PanVerification /> 
                                )}
                                
                              </Col>)} */}
                            <Col md={6} sm={12} xs={24}>
                            {paxIndex ==0 && isPanRequired && (

                                SamePanNumber ==false || (SamePanNumber ==true && roomIndex==0) ?(
                                  <PanVerification serviceType={2} paxIndex={paxIndex} roomIndex={roomIndex} />

                                  ):(" ")
                              )}
                            </Col>
                            {/* )} */}

                            {/* {pax.guestType === "Child" ? (
                              <Col md={5} sm={12} xs={24}>
                                <Form.Item
                                  name={`childAge_${roomIndex}_${paxIndex}`}
                                  label="Child Age"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => {
                                      handlePaxField(
                                        val,
                                        roomIndex,
                                        paxIndex,
                                        "age"
                                      );
                                    }}
                                  >
                                    {[...Array(12)].map((_, i) => (
                                      <Option key={i + "age"} value={i}>
                                        {i} Years
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col md={3} sm={12} xs={24}>
                                <Form.Item
                                  name={`adultage_${roomIndex}_${paxIndex}`}
                                  label="Age"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => {
                                      handlePaxField(
                                        val,
                                        roomIndex,
                                        paxIndex,
                                        "age"
                                      );
                                    }}
                                  >
                                    {[...Array(83)].map((_, i) => (
                                      <Option
                                        key={i + 12}
                                        value={i + 12}
                                      >
                                        {i + 12}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )} */}

                            {pax.hasOwnProperty("passportNo") && (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport Number"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                  name={`passportNo_${roomIndex}_${paxIndex}`}
                                >
                                  <Input
                                    className="inputbg"
                                    placeholder="Passport Number"
                                    onChange={(e) =>
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "passportNo"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            )}

                            {pax.hasOwnProperty("passportDOI") && (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport DOI"
                                  className="passport-dates"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                  name={`passportDOI_${roomIndex}_${paxIndex}`}
                                >
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      handlePaxField(
                                        dateString,
                                        paxIndex,
                                        "passportDOI"
                                      )
                                    }
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            )}

                            {pax.hasOwnProperty("passportDOE") && (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport DOE"
                                  className="passport-dates"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                  name={`passportDOE_${roomIndex}_${paxIndex}`}
                                >
                                  <DatePicker
                                    onChange={(_, dateString) =>
                                      handlePaxField(
                                        dateString,
                                        paxIndex,
                                        "passportDOE"
                                      )
                                    }
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          </Row>
                        </div>
                      ))}

                    </Card>
                  ))}{" "}
                  {insuranceData.status === 1 &&
                    insuranceData?.serviceType === 2 ? (
                    <Card
                      bordered={false}
                      className="insurance-section hotel-card-wrapper"
                    >
                      <h5>THE SMART INSURANCE COVER</h5>
                      <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                      <Row gutter={[16, 16]} align="stretch" className="mt-4">
                        <Col md={8} sm={24} xs={24}>
                          <div className="insurance-box">
                            {insuranceData.description_box_1}
                          </div>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <div className="insurance-box">
                            {insuranceData.description_box_2}
                          </div>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                          <div className="insurance-box">
                            {insuranceData.description_box_3}
                          </div>
                        </Col>
                      </Row>

                      <div className="insurance-coverage">
                        <SafetyCertificateOutlined />
                        <span>
                          Insurance Coverage Amount :{" "}
                          {insuranceData.insuranceCoverage}
                        </span>
                      </div>

                      <Form.Item
                        name="insuranceRequired"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Insurance",
                          },
                        ]}
                      >
                        <Radio.Group
                          buttonStyle="solid"
                          onChange={(e) =>
                            handleInsuranceChange(e.target.value)
                          }
                          className="insurance-radio-wrapper"
                        >
                          <Row gutter={[16, 16]}>
                            <Col md={10} sm={24} xs={24}>
                              <Radio
                                className={`radio-btn btn-insure  ${
                                  insuranceRequired === 1
                                    ? "btn-insure-active"
                                    : ""
                                  }`}
                                value={1}
                              >
                                Insure For ₹{insuranceData.amount}/pax
                              </Radio>
                            </Col>

                            <Col md={10} sm={24} xs={24}>
                              <Radio
                                value={0}
                                className={`radio-btn btn-risk ${
                                  insuranceRequired === 0
                                    ? "btn-risk-active"
                                    : ""
                                  }`}
                              >
                                I'll Risk it
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    </Card>
                  ) : null}
                </Form>
              ) : null}

             <div className="contact-header"> 
                <div className="contact-title">
                  <p className="booking-summary-div">Contact Details</p>
                </div>
              </div>
              <Card
                bordered={false}
                className="guest-details-form hotel-card-wrapper"
              >
                <Form
                  layout="vertical"
                  name="contactForm"
                  form={contact_form}
                  scrollToFirstError={true}
                  initialValues={{ areaCode: agent ? "+91" : "" }}
                >
                  <div className="guest-input-wrapper">
                    <Row gutter={16}>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            { required: true, message: "Required" },
                            { type: "email", message: "Invalid Email" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Phone number"
                          name="phoneNo"
                          className="phno"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                            {
                              minLength: 10,
                              maxLength: 10,
                              pattern: "^[0-9]{10}$",
                              message: "Must be 10 digits",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={
                              <Form.Item
                                style={{ width: "35%" }}
                                name="areaCode"
                                className="phno"
                                rules={[
                                  {
                                    required: true,
                                    message: "Phone Number Code Required",
                                  },
                                ]}
                                noStyle
                              >
                                <Select
                                  showSearch
                                  // placeholder="Select Country"
                                  style={{ width: "100%" }}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {CountryList.map((item) => (
                                    <Option
                                      key={item.dial_code}
                                      value={item.dial_code}
                                    >
                                      {item.dial_code}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            }
                          />
                        </Form.Item>
                      </Col>
  {/*
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          name="addressLine1"
                          label="Address"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          name="city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          name="state"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          name="zipCode"
                          label="ZIP/Postal code"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          name="countryCode"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Country"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {CountryList.map((item) => (
                              <Option key={item.code} value={item.code}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>  */}
                    </Row>
                  </div>
                </Form>
              </Card>
          
            </Col>

            <Col md={8} sm={24} xs={24}>
              <div className="hotel-price-wrapper">
                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <HotelFairBox
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                    isPromoVisible={true}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={16}>
              <HotelBookPayCard
                isLoading={loading}
                pgIsLoading={false}
                holdLoading={false}
                purchaseType={false}
                bookpaycardinfo={"hotel-checkout"}
                redirectToPreview={redirectToPreview}
                agent={false}
              />
            </Col>
          </Row>
        </div>
      </div>
      ) : (
      <div className="container">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24} className="border my-2">
            <Result
              status="warning"
              title="Room Not Available Please Try Again."
              extra={
                <Button type="primary" key="console" onClick={handleBackBtn}>
                  Back To Room Page
                </Button>
              }
            />
          </Col>
        </Row>
      </div>
      )}
    </div>
  );
};

export default HotelCheckout;
