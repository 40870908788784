import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Modal, Popover, Row, Col } from "antd";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import APIClient from "../../helpers/ApiClient";
import FlagsList from "./FlagsList";
import Login from "../../components/Login/Login";
import moment from "moment";
import "../navbar/Navbar.scss";
import { useActivitiesContext } from "../providers/Activities/ActivitiesProvider";

import {
  CaretDownOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
  // UserSwitchOutlined,
} from "@ant-design/icons";

const Nav = ({ location }) => {
  const { logo } = useSytContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  const {
    isLogin: { flag, agent },
    user,
    setUser,
    resetIsLogin,
  } = useAuthContext();
  const {setSelectedBuildActivities} = useActivitiesContext();
  let users=[];
  let history = useHistory();
  const [fetchingWallet, setFetchingWallet] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const [userNav, setuserNav] = useState({Services:["1","2","4","5"]});


  const logout = () => {
    resetIsLogin();
    setSelectedBuildActivities([]);
    history.push("/");
  };

  const toggleCount = () => {
    setNavToggle((prev) => !prev);
  };

  const getwalletBalance = () => {
    if (user && (agent || flag)) {
      setFetchingWallet(true);
      APIClient.get(`admin/GetAgentWalletDetails/${user?.UserID}`)
        .then((resp) => {
          if (resp.status == 200) {
            setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
          }
          setFetchingWallet(false);
        })
        .catch((error) => {
          setFetchingWallet(false);
        });
    }
  };
  /*============= check user logged or not =========== */
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });

  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });
  };
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.login === true) {
        showModal1();
      }
    }
  }, [location]);

  const Balance = (
    <Menu>
      <div className="nav-wallet-wrapper">
        {agent ? (
          <span className="d-flex align-items-center justify-content-between">
            Cash : INR{" "}
            {user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : ""}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        ) : (
          <span className="d-flex align-items-center justify-content-between">
            Balance : INR
            {user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : ""}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        )}
      </div>

      {user && user?.Role?.RoleLevel == 3 ? (
        <>
          {user?.Walletdetails?.CreditAmount >= 0 ? (
            <div className="nav-wallet-wrapper">
              <b>Credit Amount : {user?.Walletdetails?.CreditAmount}</b>
            </div>
          ) : null}
          {user ? (
            user?.Walletdetails?.ExpiryDate ? (
              <div className="nav-wallet-wrapper">
                <p className="mb-0">
                  Credit Expires :{" "}
                  {user?.Walletdetails?.ExpiryDate
                    ? moment(user?.Walletdetails?.ExpiryDate).format(
                        "DD-MM-YYYY"
                      )
                    : ""}
                </p>
              </div>
            ) : null
          ) : null}

          <Menu.Item key="15">
            <NavLink
              exact
              activeClassName="selectedNav"
              className="cre-amount-14"
              to="/deposits"
            >
              TOPUP
            </NavLink>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );
  const loginMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/profile">
          My Profile
        </NavLink>
      </Menu.Item>

      <Menu.Item key="2">
        <NavLink exact activeClassName="selectedNav" to="/booking-reports">
          Orders
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink exact activeClassName="selectedNav" to="/mypackage">
          My Package
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink exact activeClassName="selectedNav" to="/wallet">
          Wallet
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
          Coupon Wallet
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/traveller-details">
          Add Passenger Details
        </NavLink>
      </Menu.Item>
      <Menu.Item key="7">
        <NavLink exact activeClassName="selectedNav" to="/transaction-reports">
          Transaction Reports
        </NavLink>
      </Menu.Item>
      <Menu.Item key="8">
        <NavLink exact activeClassName="selectedNav" to="/change-password">
          Change Password
        </NavLink>
      </Menu.Item>
      {/* Add Logout Features On Mobile View --27/May/2023 TaskId-1003 */}
      <Menu.Item key="11" className="border-bottom-0">
        <NavLink to="/" exact activeClassName="selectedNav" onClick={logout}>
          Logout
        </NavLink>
      </Menu.Item>
      {/* Add Logout Features On Mobile View --27/May/2023 TaskId-1003 */}
    </Menu>
  );

  const AgentMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/myprofile">
          My Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink exact activeClassName="selectedNav" to="/commission-details">
          My Commissions
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink exact activeClassName="selectedNav" to="/bank-details">
          Bank Details
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink exact activeClassName="selectedNav" to="/agent-markup">
          Markups
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/deposits">
          Deposits
        </NavLink>
      </Menu.Item>
      <Menu.Item key="11">
        <NavLink exact activeClassName="selectedNav" to="/agent-logo">
          Logo
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/store-vistors">
          Store Visitors
        </NavLink>
      </Menu.Item>
      <Menu.Item key="7">
        <NavLink exact activeClassName="selectedNav" to="/booking-reports">
          Orders
        </NavLink>
      </Menu.Item>
      <Menu.Item key="8">
        <NavLink exact activeClassName="selectedNav" to="/mypackage">
          My Package
        </NavLink>
      </Menu.Item>
      <Menu.Item key="9">
        <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
          Coupon Wallet
        </NavLink>
      </Menu.Item>
      <Menu.Item key="13">
        <NavLink exact activeClassName="selectedNav" to="/discounts">
          Discounts
        </NavLink>
      </Menu.Item>
      <Menu.Item key="12">
        <NavLink exact activeClassName="selectedNav" to="/statements">
          Statements
        </NavLink>
      </Menu.Item>
      <Menu.Item key="10" className="border-bottom-0">
        <NavLink exact activeClassName="selectedNav" to="/change-password">
          Change Password
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="11" className="border-bottom-0">
        <NavLink to="/" exact activeClassName="selectedNav" onClick={logout}>
          Logout
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );
  const MainMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="21">
        <div onClick={() => showModal1("USER")}>User Login</div>
      </Menu.Item>
      <Menu.Item key="22">
        <div onClick={() => showModal1("AGENT")}>Partner Login</div>
      </Menu.Item>
      <Menu.Item key="24">
        <NavLink exact activeClassName="selectedNav" to="/user-registration">
          User Registration
        </NavLink>
      </Menu.Item>
      <Menu.Item key="25" className="border-bottom-0">
        <NavLink exact activeClassName="selectedNav" to="/agent-registration">
          Partner Registration

        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const UserDetailsContent = (
    <div>
      <ul className="userPopOver text-capitalize">
        <li><small>name : <strong>{user ? user.FirstName + " " + user.LastName : "Guest"}</strong></small></li>
        <li><small>email : <strong>{user ? user.Email: null}</strong></small></li>
        <li><small>phone no. : <strong>{user ? user.Mobile : null}</strong></small></li>
      </ul>
    </div>
  )

  const RepresentativeDetails  = (
    <div className="representative-details">
      <div className="info-details d-flex justify-content-between font-weight-bold">
        <p>Empolyee Id : </p>
        <p>{user?.reprentive?.empId}</p>
      </div>
      <div className="info-details d-flex justify-content-between font-weight-bold">
        <p>Name : </p>
        <p>{user?.reprentive?.name}</p>
      </div>
      <div className="info-details d-flex justify-content-between font-weight-bold">
        <p>Phone : </p>
        <p>{user?.reprentive?.phone}</p>
      </div>
      <div className="info-details d-flex justify-content-between font-weight-bold">
        <p>Email : </p>
        <p><small><b>{user?.reprentive?.email}</b></small></p>
      </div>
      <div className="info-details d-flex justify-content-between font-weight-bold">
        <p>Designation : </p>
        <p>{user?.reprentive?.department}</p>
      </div>?
    </div>
  )

  const NavbarLinks = ({ user, isGuest }) => {
    let services = user?.Services ? user?.Services : [];
    return (
      <>
        {(isGuest || services.includes("1")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/">
              Flights
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("2")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/hotels">
              Hotels{" "}
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("3")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/bus">
              Bus{" "}
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("4")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/activities">
              Tours & Transfers{" "}
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("5")) && (
          <li style={{ borderRight: "none" }} onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/buildyourpackage">
              Build Package{" "}
            </NavLink>
          </li>
        )}

        {(isGuest || services.includes("7")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/visa">
              Visa
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("8")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/insurance">
              Insurance
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("9")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/forex">
              Forex
            </NavLink>
          </li>
        )}

        {(isGuest || services.includes("6")) && agent && (
          <li style={{ borderLeft: "1px solid #c7c7c7" }} onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav" to="/sastaeurope">
              Sasta Europe{" "}
            </NavLink>
          </li>
        )}
      </>
    );
  };

  return (
    <div className="header-bg">
      <div className="header-container">
        <div className="wrapper">
          <div className="top-nav-sec">
            <ul className="all-top-menu">
              <li className="mob-none cart-nav-top">
                {user &&
                Object.keys(user).length > 0 &&
                (flag == true || agent == true) ? (
                  <React.Fragment>
                    Welcome {user?.FirstName}{" "}
                    {agent ? `(${user?.AgentID})` : null}
                  </React.Fragment>
                ) : null}
              </li>
              <li className="mob-none cart-nav-top">
                {user && user?.hasOwnProperty('reprentive') && Object.keys(user?.reprentive)?.length > 0 ? (
                  <Popover defaultVisible content={RepresentativeDetails} title="Representative Details">
                    Representative Details
                  </Popover>
                ) : null}
              </li>
              {agent !== true ? (
                <li className="mob-none cart-nav-top" onClick={toggleCount}>
                  <NavLink exact to="/mytrips">
                    <span className="user-btn-wrapper">Manage Trips</span>
                  </NavLink>
                </li>
              ) : null}

              <li className="mob-none cart-nav-top" onClick={toggleCount}>
                <NavLink exact to="/activities/cart">
                  <span className="user-btn-wrapper">
                    <ShoppingCartOutlined />
                    Cart
                  </span>
                </NavLink>
              </li>

              <li className="currency-text last-mob-bdr mob-none">
                <FlagsList />
              </li>
              {agent == true ? (
                <li className="mob-none cart-nav-top">
                  <Dropdown overlay={Balance} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      Balance <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </li>
              ) : null}

              {user && !agent ? (
                <li className="mob-none cart-nav-top">
                  <Dropdown overlay={Balance} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      Wallet <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </li>
              ) : null}
              {flag === true || agent === true ? (
                <li className="mob-none cart-nav-top">
                  <NavLink
                    to="/"
                    exact
                    activeClassName="selectedNav"
                    onClick={logout}
                  >
                    Logout
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>

          <header className="header">
            <div className="app-logo">
              <div className="company-logo" onClick={toggleCount}>
                <NavLink exact activeClassName="selectedNav" to="/">
                  {/* {agent === true ? (
                    agentLogo ? (
                      <img src={BASE + agentLogo.substring(1)} alt="SYT_Logo" />
                    ) : (
                      <img
                        src={
                          require("../../assets/images/logos/trip-logo.png")
                            .default
                        }
                        alt="shopyourtrip"
                      />
                    )
                  )} */}

                  {logo ? (
                    <img src={BASE + logo.substring(1)} alt="SYT_Logo" />
                  ) : (
                    <img
                      src={
                        require("../../assets/images/logos/trip-logo.png")
                          .default
                      }
                      alt="shopyourtrip"
                    />
                  )}
                </NavLink>
              </div>
              <div className="user-icon">
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <span className="d-block d-md-none mobile-user">
                  <div className="mobile-user-icon">
                    {flag === true ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined />
                        </span>
                      </Dropdown>
                    ) : agent === true ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined />
                        </span>
                      </Dropdown>
                    ) : (
                      <span className="user-btn-wrapper" onClick={showModal1}>
                        <UserOutlined />
                      </span>
                    )}
                  </div>
                </span>
                <label className="menu-icon" onClick={toggleCount}>
                  <span className="navicon"></span>
                  <span className="menu-text d-block d-md-none">Menu</span>
                </label>
              </div>
            </div>
            <div className="app-links">
              <ul
                className="menu"
                style={{ maxHeight: navToggle ? "610px" : "0px" }}
              >
                {agent ? (
                  <NavbarLinks user={user} isGuest={false} />
                ) : (
                  <NavbarLinks user={userNav} isGuest={false} />//Temporary hide navigation links for users  user?.Services
                )}

                {user?.Role?.RoleId == 5 || user?.Role?.RoleId == 2 ? (
                  <li style={{ borderLeft: "1px solid #c7c7c7" }} onClick={toggleCount}>
                  <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
                    Redeem Coupon
                  </NavLink>
                </li>
                ) : null}

                {/*<li onClick={toggleCount} className={`${agent ? null : "border-right-0"}`}>
                                    <NavLink exact activeClassName="selectedNav" to="/offers"> Offers </NavLink>
                </li> */}    
                {agent && (
                  <li onClick={toggleCount} className="border-right-0">
                    <NavLink
                      exact
                      activeClassName="selectedNav"
                      to="/statements"
                    >
                      Ledger Statements
                    </NavLink>
                  </li>
                )}

                <li
                  onClick={() => showModal1("USER")}
                  className="border-right-0"
                  id="only-mob-user"
                >
                  <NavLink exact activeClassName="selectedNav" to="">
                    User Login
                  </NavLink>
                </li>
                {/* Add User And Partner Registration On Mobile View --27/May/2023 TaskId-1003 */}
                <li onClick={toggleCount} id="only-mob-user">
                  <NavLink
                    exact
                    activeClassName="selectedNav"
                    to="/user-registration"
                  >
                    User Registration
                  </NavLink>
                </li>
                <li onClick={toggleCount} id="only-mob-user">
                  <NavLink
                    exact
                    activeClassName="selectedNav"
                    to="/agent-registration"
                  >
                    Partner Registration
                  </NavLink>
                </li>
                {/* Add User And Partner Registration On Mobile View --27/May/2023  TaskId-1003 */}

                <ul className="d-block d-md-none responive-more">
                  <li className="currency-text currency-panel-text">
                    <FlagsList />
                  </li>
                </ul>
              </ul>
              {user ? (
                <ul className="menu">
                  <li className="border-right-0 mob-none">
                    {flag ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined /> My Account{" "}
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : agent ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined /> My Account
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : null}
                  </li>
                </ul>
              ) : (
                <ul className="menu">
                  <li className="border-right-0 mob-none">
                    <Dropdown overlay={MainMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper">
                        Login | Register
                        <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  </li>
                </ul>
                // <span
                //   className="user-btn-wrapper login-register-mob"
                //   onClick={showModal1}
                // >
                //   <UserOutlined /> Login | Register
                // </span>
              )}
            </div>
          </header>
        </div>
      </div>

      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={location}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </div>
  );
};

export default withRouter(Nav);
