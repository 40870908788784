import React, { useState, useEffect } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import { useLocalStorage } from "../../helpers/useStorage";

import { message } from "antd";

const defCombinedSearch = {
  tripType: "oneWay",
  classType: "Economy",
  origin: [],
  destination: [],
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: "",
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
};

const GlobalStatesProvider = (props) => {
  const [busPersist, setBusPersist] = useLocalStorage("busPersist", {
    busSearchObj: {
      from: null,
      to: null,
      departureDate: new Date(),
    },
    selectedBusData: [],
  });
  const [tourLiveList, setTourLiveList] = useState([]);
  const [busPassengerData, setBusPassengerData] = useState([]);
  const [busBookingResp, setBusBookingResp] = useState([]);
  //const [isInternation, setisInternation] = useState(0);
  const [busSearchResultObj, setBusSearchResultObj] = useState({});

  const [otherData, setOtherData] = useState({
    promoData: { status: false, Discount: 0 },
    ConvFee: { status: false, amount: 0 },
    isInternation: 0,
    insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    redeemAmount: { CouponAmt: 0, status: false },
    selectedoutMeal: 0,
    SelectedoutbondBagges: 0,
    SelectedInbondMeal: 0,
    SelectedInbondBagges: 0,
    transactionFee: { status: false, amount: 0 },
    PanVerificationDetails: { isloading: false },
  });

  const validatePromoCode = (req) => {
    ApiClient.post("admin/validate/promo", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            promoData: { ...res.data, status: true },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => { });
  };

  const PanVerification = (req) => {

    setOtherData((prev) => ({
      ...prev,
      PanVerificationDetails: {isloading: true},
    }))
    ApiClient.post('hotels-v2/verificationPan/', req)
      .then((res) => {
      
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            PanVerificationDetails: { ...res.data, status: false,paxIndex:req.paxIndex,
              roomIndex:req.roomIndex },
            
          }));
        } else {
          setOtherData((prev) => ({
            ...prev,
            PanVerificationDetails: {isloading: false,  paxIndex:req.paxIndex,
              roomIndex:req.roomIndex},
          
          }))
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => { });
  };

  const validateRedeemCoupon = (req) => {
    ApiClient.post("admin/validateCouponAmt", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            redeemAmount: { CouponAmt: res.appliedCouponAmt, status: true },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => { });
  };

  const RemovePromoConvFee = () => {
    setOtherData({
      promoData: { status: false, Discount: 0 },
      ConvFee: { status: false, amount: 0 },
      insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      redeemAmount: { CouponAmt: 0, status: false },
    });
  };

  const RemovePromo = () => {
    setOtherData((prev) => ({
      ...prev,
      promoData: { status: false, Discount: 0 },
    }));
  };

  const RemoveRedeemCoupon = () => {
    setOtherData((prev) => ({
      ...prev,
      redeemAmount: { CouponAmt: 0, status: false },
    }));
  };


  const AddConvFee = (type,isRoundTrip=0,isInternation=0) => {
    ApiClient.get('admin/conveniencefeebyservicetype', {
      serviceType: type,
      isRoundTrip: isRoundTrip,
      isInternation:isInternation
    })
      .then((res) => {
        if (res.status === 200) {
          let convobj = {
            id: res.data.ID,
            status: true,
            amount: res.data.Value,
            type: res.data.ConvenienceFeeType,
          };
          setOtherData((prev) => ({
            ...prev,
            ConvFee: convobj,
          }));
        }
      })
      .catch((error) => { });
  };
  const AddTransFee = (type) => {
    ApiClient.get('admin/getTransactionFeeByType', {
      serviceType: type
    })
      .then((res) => {
        if (res.status === 200) {
          let convobj = {
            id: res.data.Id,
            status: true,
            amount: res.data.FeePer,
            type: res.data.FeeType,
            methodType: type
          };
          setOtherData((prev) => ({
            ...prev,
            transactionFee: convobj,
          }));
        }
      })
      .catch((error) => { });
  };
  const getInsuranceByServiceType = (type) => {
    ApiClient.get(`admin/getInsuranceByserviceType?serviceType=${type}`)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            insuranceData: resp.data,
          }));
        } else {
          setOtherData((prev) => ({
            ...prev,
            insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
          }));
        }
      })
      .catch((err) => err);
  };

  // PART PAYMENT

  const [partPayment, setPartPayment] = useState({
    isPartPayment: false,
    ServiceType: 1,
    PartPaymentPercentage: 0,
  });

  const [PartPayCapable , setPartPayCapable] = useState(false)

  const  getPartPayment = async (type, value) => {
    if (type == 2) {
      let obj = {
        BusinessType: value.BusinessType,
        ServiceType: value.ServiceType,
      };
      await ApiClient.get("activities/tours/getliveTourList")
    .then((result) => {
      setTourLiveList(result);
    })
      ApiClient.post("admin/partPayment", obj)
        .then((resp) => {
          if (resp.status == 200) {
            let catId = resp?.data?.CategoryID
              ? resp?.data?.CategoryID.split(",")
                .map((item) => {
                  if (item == 40) {
                    // return parseInt(item);
                    // Start Here Code On Testing 
                    let tourDate = resp?.data?.MinDaysToEnablePartPay;
                    let currentDate = new Date();
                    let count = 0;
                    value.quotation?.TourDetails.map((tour) => {
                      const diffTime = new Date(tour[0].tourDate) - currentDate;
                      const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                      if (days < tourDate)
                        count++;
                      if (count > 0) {
                        message.error("Part Payment Not Availale Under Your Tour Date");
                        // document.getElementById("PartPaymentAviality").style = "display:none";
                        setPartPayCapable(false);
                      }else{
                        setPartPayCapable(true);
                      }
                    })
                    // End Here Code On Testing 
                  }
                })
                .filter((item) => item)
              : [];
            let data = {
              ...resp.data,
              isPartPayment: true,
              ServiceType: resp.data.ServiceType,
              PartPaymentPercentage: parseFloat(
                resp.data.PartPaymentPercentage
              ),
              CategoryID: catId,
              categories: resp?.data?.categories ?? [],
            };
            setPartPayment(data);
          } else {
            setPartPayment({
              ...partPayment,
              isPartPayment: false,
              PartPaymentPercentage: 0,
            });
            message.error("Part Payment Not Availale For The Moment", 3);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      setPartPayment({
        ...partPayment,
        isPartPayment: false,
        PartPaymentPercentage: 0,
      });
    }
  };

  //Hydrating Bus Data from localstorage

  //Hydrating Combined Data from localstorage
  const combinedPersistJson =
    JSON.parse(localStorage.getItem("combinedPersist")) || {};
  let initCombinedSearch =
    combinedPersistJson.combineSearchData || defCombinedSearch;

  const [airlineMatrixReset, setAirlineMatrixReset] = useState(false);

  const [tourPassangerData, setTourPassangerData] = useState([]);

  const [combineSearchData, setCombineSearchData] =
    useState(initCombinedSearch);

  useEffect(() => {
    //Bus Data in localstorage
    localStorage.setItem(
      "combinedPersist",
      JSON.stringify({
        combineSearchData: combineSearchData,
      })
    );
  }, [combineSearchData]);

  return (
    <GlobalStatesContext.Provider
      value={{
        state: {
          busPersist,
          busPassengerData,
          busBookingResp,
          combineSearchData,
          busSearchResultObj,
          otherData,
          tourPassangerData,
          airlineMatrixReset,
          partPayment,
          PartPayCapable,
          tourLiveList
        },
        ResetAirlineMatrix: (val) => {
          setAirlineMatrixReset(val);
        },

        validatePromoCode: (val) => {
          validatePromoCode(val);
        },
        PanVerification: (val) => {
          PanVerification(val);
        },
        getPartPayment: (e, val) => {
          getPartPayment(e, val);
        },

        AddConvFee: (v,x,isInternation) => {
          AddConvFee(v,x,isInternation);
        },
        AddTransFee: (v) => {
          AddTransFee(v);
        },
        getInsuranceByServiceType: (v) => {
          getInsuranceByServiceType(v);
        },
        updateOtherData: (val) => {
          setOtherData(val);
        },
        setSelectedInsuranceData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            selectedInsuranceData: val,
          }));
        },
        RemovePromo: () => {
          RemovePromo();
        },
        RemoveRedeemCoupon: () => {
          RemoveRedeemCoupon();
        },
        RemovePromoConvFee: () => {
          RemovePromoConvFee();
        },
        setBusSearchObj: (searchObj) => {
          setBusPersist((prev) => ({
            ...prev,
            busSearchObj: searchObj,
          }));
        },
        updateBusSearchResultObj: (busSearchResp) => {
          setBusSearchResultObj(busSearchResp);
        },
        updateSelectedBusData: (selectedbusobj) => {
          setBusPersist((prev) => ({
            ...prev,
            selectedBusData: [selectedbusobj],
          }));
        },
        updateBusPassengerData: (obj) => {
          setBusPassengerData(obj);
        },
        updateBusBookingData: (respObj) => {
          setBusBookingResp(respObj);
        },

        updataCombineSearchObj: (attribute, value) => {
          setCombineSearchData({ ...combineSearchData, [attribute]: value });
        },

        updateTourPassangerData: (obj) => {
          setTourPassangerData(obj);
        },
        validateRedeemCoupon: (val) => {
          validateRedeemCoupon(val);
        },
        setSelectedInbondBagges: (val) => {
          setOtherData((prev) => ({
            ...prev,
            SelectedInbondBagges: val,
          }));
        },
        setSelectedoutbondMeal: (val) => {
          setOtherData((prev) => ({
            ...prev,
            selectedoutMeal: val,
          }));
        },
        setSelectedoutbondBagges: (val) => {
          setOtherData((prev) => ({
            ...prev,
            SelectedoutbondBagges: val,
          }));
        },
        setIsInternational: (val) => {
          setOtherData((prev) => ({
            ...prev,
            isInternational: val,
          }));
        },
        setSelectedInbondMeal: (val) => {
          setOtherData((prev) => ({
            ...prev,
            SelectedInbondMeal: val,
          }));
        },


      }}
    >
      {props.children}
    </GlobalStatesContext.Provider>
  );
};
export default GlobalStatesProvider;
