import React, { useState } from "react";
import { Button, Card, Col, Row, Layout, TimePicker, message } from "antd";
import "../Hotelpkgroom/Hotelpkgroom.scss";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";
import Table from "react-bootstrap/Table";
import ApiClient from "../../../helpers/ApiClient";
import moment from "moment";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import dayjs from 'dayjs';

import { useHistory } from "react-router-dom";

const Hotelpkgroom = ({
  searchReqBuildActivities,
  isCartModify,
  packageId,
}) => {
  const { Content } = Layout;

  const history = useHistory();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const { user, adminAsUser } = useAuthContext();
  // const { countTranferTour, setCountTranferTour} = useState(0);
  // let countTranferTour = 0;
  // let countUpdateTime = 0;

  const {
    state: { selectedBuildActivities, selectedBuildHotel },
    setSelectedBuildHotel,
    setSelectedBuildActivities,
  } = useActivitiesContext();

  let noOfNight = 0;
  if (
    searchReqBuildActivities != null &&
    Object.keys(searchReqBuildActivities).length > 0
  ) {
    let checkin = new Date(searchReqBuildActivities.checkInDate);
    let checkout = new Date(searchReqBuildActivities.checkOutDate);

    let diffTime = checkout - checkin;

    let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));
    noOfNight = diffDays;
  } else {
    noOfNight = 0;
  }

  const getAdultChildCount = () => {
    let adults = 0;
    let childs = 0;

    if (Object.keys(searchReqBuildActivities).length > 0) {
      let roomGuests = JSON.parse(searchReqBuildActivities.roomGuests);
      if (roomGuests && roomGuests?.length > 0) {
        for (let index = 0; index < roomGuests.length; index++) {
          adults += Number(roomGuests[index].noOfAdults);
          childs += Number(roomGuests[index].noOfChilds);
        }
      }
    }

    return adults + childs;
  };

  const CreateQuotation = async () => {
    let error = 0;
    let hotels = selectedBuildHotel.map((sh) => {
      if (sh) {
        return {
          hotelsCode: sh.hotelCode,
          rooms: sh.roomCombination,
        };
      }
    });

    let activity = [];

    selectedBuildActivities.map((item) => {
      if (item) {
        item.forEach((tour) => {
          if(tour.startTime == null){
            message.error(`${tour.tourName} Please Enter Valid Time`, 2, 2);
            error = 1;
          }else{
          activity.push({
            ...tour,
          });
        }
        });
      }
    });

    let results = activity.reduce((results, org) => {
      (results[org] = results[org] || []).push(org);
      return results;
    }, {});

    let tourList = Object.keys(results).map((key) => {
      if (results[key]?.length > 0) {
        return {
          tourId: key,
          tourOptionDetail: results[key],
        };
      }
    });

    if (hotels.length > 0 || activity.length > 0 && error == 0) {
      let reqObj = {
        UserId: user ? user?.UserID : 1,
        RoleType: user ? user?.Role?.RoleId : 4,
        TravelDate: moment(
          searchReqBuildActivities.checkInDate,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY"),
        Destination: "Hyderabad, India",
        packageRefNo: packageId,
        hotelTraceId: selectedBuildHotel[0]?.traceId
          ? selectedBuildHotel[0]?.traceId
          : "",
        tourTraceId: activity[0]?.traceId,
        isFrompackage: true,
        hotels: hotels,
        Tours: tourList,
        createdBy: user?.UserID ?? 0,
        modifiedBy: adminAsUser.status ? adminAsUser.id : 0,
        buildPackageRequest: JSON.stringify(searchReqBuildActivities),
      };

      isCartModify ? updatePackage(reqObj, reqObj) : createPackage(reqObj);
    }
  };

  const createPackage = (reqObj) => {
    ApiClient.post("buildPackage/buildPackage", reqObj)
      .then((results) => results)
      .then((resp) => {
        if (resp.status == 200 && Object.keys(resp.data).length > 0) {
          let query = resp.data.PackageDetails.PackageRefNo;
          history.push("/buildquotationtotal?packageRefNo=" + query);
          // countUpdateTime = 0;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePackage = (reqObj) => {
    ApiClient.put(`buildPackage/updatePackage/${packageId}`, {}, reqObj)
      .then((results) => results)
      .then((resp) => {
        if (resp.status == 200 && Object.keys(resp.data).length > 0) {
          let query = resp.data.PackageDetails.PackageRefNo;
          history.push("/buildquotationtotal?packageRefNo=" + query);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hotelGrandTotal = (hotels) => {
    let Total = 0;
    hotels.rooms.forEach((R) => {
      let total = R.ratePlans.reduce(
        (a, b) => a + parseFloat(b.price.total),
        0
      );
      Total += parseFloat(total);
    });
    return currencyValue(Total);
  };

  const removeHotel = (hotelCode) => {
    let remainingHotel = selectedBuildHotel.filter(
      (hotel) => hotel.hotelCode != hotelCode
    );
    setSelectedBuildHotel(remainingHotel);
  };

  const removeActivities = (key, index) => {
    if (selectedBuildActivities.length > 0) {
      if (selectedBuildActivities[key].length > 0) {
        let copySelected = [...selectedBuildActivities];
        copySelected[key] = copySelected[key].filter((_, i) => i != index);
        setSelectedBuildActivities(copySelected);
      }
    }
  };

  const getTimebyUser = (optionData) => {
    if (
      (optionData.cityTourType.includes('Airport') ||
        optionData.cityTourType.includes('Transfer') &&
        optionData.transferName === "Private Transfers")
    ) {
      const isValidTimeFormat = !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(optionData.startTime) && /^\d{2}:\d{2}:\d{2}$/.test(optionData.startTime);
      if(!isValidTimeFormat) {
        selectedBuildActivities.forEach(activity => activity.forEach(ele => ele.tourId === optionData.tourId && (ele.startTime = null)));
      }
      return true;
    } else {
      return false;
    }
  };

  function onChange(time, ID) {
    // if(time == null){
    //   countUpdateTime--;
    // }else{
      const Time = time?.format('HH:mm:ss');
      selectedBuildActivities.forEach(activity => activity.forEach(ele => ele.tourId === ID && (ele.startTime = Time)));
    //   countUpdateTime++;
    // }
  }


  return (
    <Layout className="travel-img">
      <Content className="admin-container cms-pages-width activity-cms">
        {selectedBuildHotel.length > 0 &&
          selectedBuildHotel.map((hotel, i) => {
            return (
              <Row
                key={i + "selectedBuildHotel"}
                className="quotation-card mb-4"
              >
                <Col md={24} xs={24}>
                  <Card>
                    <div className="quotation-header d-flex align-items-center justify-content-between">
                      <h5 className="activi-hotelinfo flex-fill">
                        {hotel.hotelName}{" "}
                        {Object.keys(searchReqBuildActivities).length > 0
                          ? moment(searchReqBuildActivities.checkInDate).format(
                            "DD MMM YYYY"
                          )
                          : ""}
                      </h5>
                      <span
                        className="close-fa"
                        onClick={() => removeHotel(hotel.hotelCode)}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    </div>
                    <Button className="quotation-btn">Hotel</Button>

                    <div className="hotel-table-name">
                      <p>
                        {" "}
                        {hotel.hotelName}({noOfNight})
                      </p>
                      <span>
                        {Object.keys(searchReqBuildActivities).length > 0 ? (
                          <span>
                            {moment(
                              searchReqBuildActivities.checkInDate
                            ).format("DD MMM YYYY")}
                            {" - "}
                            {moment(
                              searchReqBuildActivities.checkOutDate
                            ).format("DD MMM YYYY")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <span className="guestpersons">
                        <i className="fa fa-user" aria-hidden="true"></i>
                        &nbsp; {getAdultChildCount()} guest,
                      </span>
                    </div>

                    <div className="hotel-table-name">
                      {hotel.rooms.map((room, index) => {
                        return (
                          <div
                            key={index + "hotel-room-table"}
                            className="hotel-room-table"
                          >
                            <p>Room {index + 1}</p>
                            <Table
                              responsive="xl"
                              striped
                              bordered
                              hover
                              className="totalofdata"
                            >
                              <thead>
                                <tr>
                                  <th>Room Type</th>
                                  <th>No.Of Nights</th>
                                  <th>Price ({noOfNight}) Nights</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{room.roomName}</td>
                                  <td>{noOfNight}</td>
                                  <td>
                                    {activeCurrency}{" "}
                                    {currencyValue(Number(
                                      room.ratePlans[0].price.total
                                    ).toFixed(2))}
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="3">
                                    <p className="totalofone">
                                      Total {activeCurrency}{" "}
                                      {currencyValue(Number(
                                        room.ratePlans[0].price.total
                                      ).toFixed(2))}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        );
                      })}
                      <Row className="grandoption">
                        <Col md={12} xs={12}>
                          <p className="grandone-1">Grand Total</p>
                        </Col>
                        <Col md={12} xs={12}>
                          <p className="grandone">{activeCurrency} {hotelGrandTotal(hotel)}</p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            );
          })}

        {selectedBuildActivities.length > 0 &&
          selectedBuildActivities.map((activityperDay, index) => {
            return (
              <div key={"selectedBuildActivities" + index}>
                {activityperDay &&
                  activityperDay?.length > 0 &&
                  activityperDay.map((activity, i) => {
                    return (
                      <Row
                        key={"activityperDay" + i}
                        className={`quotation-card ${i == 0 ? "" : "mt-2"}`}
                      >
                        <Col md={24} xs={24}>
                          {i == 0 ? <h5>Day-{index + 1}</h5> : null}
                          <Card>
                            <div className="quotation-header d-flex align-items-center justify-content-between">
                              <h5 className="activi-hotelinfo flex-fill">
                                {activity.tourName}{" "}
                                {moment(activity.tourDate, "YYYY-MM-DD").format(
                                  "DD MMM YYYY"
                                )}
                              </h5>
                              <span
                                className="close-fa"
                                onClick={() => removeActivities(index, i)}
                              >
                                <i className="fa fa-times"></i>
                              </span>
                            </div>
                            <div className="hotel-table-name">
                              <p>Tour Name: {activity.tourName}</p>
                              <p>Transfer Type: {activity.transferName}</p>
                              <p>Start Timings: {getTimebyUser(activity) !== true ? activity.startTime : <TimePicker onChange={(time) => onChange(time, activity.tourId)} defaultOpenValue={dayjs('00:00', 'HH:mm')} format="HH:mm"  required />}</p>
                              <p>Tour Option : {activity.optionName}</p>
                            </div>
                            <div className="hotel-table-name">
                              <Table
                                responsive="xl"
                                striped
                                bordered
                                hover
                                className="totalofdata"
                              >
                                <thead>
                                  <tr>
                                    <th>Adult</th>
                                    {activity?.child > 0 && <th>Child</th>}

                                    {activity?.infant > 0 && <th>Infant</th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {activity.adult}
                                      {/* X{" "}
                                      {Number(activity.adultRate).toFixed(2)} */}
                                    </td>
                                    {activity?.child > 0 && (
                                      <td>
                                        {activity.child}
                                        {/* X{" "}
                                        {Number(activity.childRate).toFixed(2)} */}
                                      </td>
                                    )}
                                    {activity?.infant > 0 && (
                                      <td>
                                        {activity.infant}
                                        {/* X{" "}
                                        {Number(activity.infantRate).toFixed(2)} */}
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </Table>
                              <Row className="grandoption">
                                <Col md={12} xs={24}>
                                  <p className="grandone-1">Grand Total</p>
                                </Col>
                                <Col md={12} xs={24}>
                                  <p className="grandone">
                                    {activeCurrency} {parseInt(currencyValue(activity.serviceTotal))}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            );
          })}

        <Row className="grandoption">
          <Col md={24} xs={24}>
            <Button
              className="create-auotation"
              data-dismiss="modal"
              aria-label="Close"
            >
              Add More
            </Button>
          </Col>
          <Col md={24} xs={24}>
            <Button
              className="create-auotation"
              data-dismiss="modal"
              // onClick={() => countTranferTour == countUpdateTime ? CreateQuotation() : message.error('Please Select Time For Each Tour', [2])}
              onClick={() => CreateQuotation()}
            >
              {isCartModify ? "Save" : "Create"} Package
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Hotelpkgroom;
