import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Radio,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
  Image,
  Input,
} from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import { useSytContext } from "../../providers/SytProvider";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
import TicketSidebar from "../TicketSidebar";
import { LoadingOutlined } from "@ant-design/icons";
import TicketActivites from "./TicketActivities";

const { Panel } = Collapse;

const ActivitiesTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const [logoImage, setLogoImage] = useState(logo);
  const ticketSearchParams = queryString.parse(window.location.search);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const BASE = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [cmsFareRules, setCmsFareRules] = useState({});
  const initialState = {
    TourDetails: [],
    Passengers: {},
    PartPayment: {},
  };
  const [VoucheredModalVisible, setVoucheredModalVisible] = useState(false);
  const [voucherData, setVoucherData] = useState(false);
  const [ticketData, setTicketData] = useState(initialState);
  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const mapActivitiesData = (res) => {
    if (res.data?.TourDetails) {
      let data = {
        ...res.data,
        TourDetails: res.data.TourDetails.map((item) => ({
          ...item,
          TransferObj : item.transferDetails,
          serviceTotal: Number(item.serviceTotal) + Number(item.postMarkup),
        })),

        totalPostMarkup: res.data.TourDetails.reduce(
          (a, b) => a + Number(b.postMarkup),
          0
        ),
      };

      setTicketData(data);
    } else {
      setTicketData(initialState);
    }
  };

  useEffect(() => {
    if (agent && agentLogo) {
      setLogoImage(agentLogo);
    }
  }, [agent, agentLogo]);


  // Function Add By Karthik 
  const toggleVoucheredModal = () => {
    setVoucheredModalVisible((prev) => !prev);
  };
  const { TextArea } = Input;
  const VoucheredConfirm = () => {
    setVoucheredModalVisible((prev) => !prev);
    const updatedTourDetails = ticketData?.TourDetails?.map((item) => {
      if (item?.BookingStatus === "CONFIRMED" && item?.bookingStatus === 3 || item?.bookingStatus === 7) {
        // item.BookingStatus = "Voucher";
        // item.bookingStatus = 11;
        UpdateTicketVourcherStatus()
      } else {
        return item;
      }
    });
  };

  const UpdateTicketVourcherStatus = () => {
    APIClient.post("activities/tours/updatevourcherstatus", {refNo:ticketSearchParams.ref})
      .then((result) => result)
      .then((resp) => {
        if (resp.status == 200) {
          setVoucherData(true);
          getTicketDetails();
          message.success("Ticket Voucher Updated Successfully");
        } else {
          message.error("Can't Voucher Ticket");
        }
      })
      .catch((error) => { });
  };

  // Code End by karthik

  const getTicketDetails = () => {
    setLoadingTicket(true);
    APIClient.get("activities/tours/ticketDetails/" + ticketSearchParams.ref)
      .then((res) => {
        if (res.statusCode == 200) {
          if (mode === "USER") {
            let userId = user?.UserID ?? 1;
            if (res.data.UserId === userId) {
              mapActivitiesData(res);
            } else {
              setTicketData(initialState);
            }
          } else {
            mapActivitiesData(res);
          }
        } else {
          setTicketData(initialState);
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData(initialState);
        setLoadingTicket(false);
      });
  };

  // const UpdateTicketVourcherStatus = () => {
  //   //setLoadingTicket(true);

  //   APIClient.post("ctivities/tours/updatevourcherstatus", ticketSearchParams.ref)
  //     .then((result) => result)
  //     .then((resp) => {
  //       if (resp.statusCode == 200 && resp?.data) {
  //         setTourTimeSlot(resp.data);
  //       } else {
  //         setTourTimeSlot([]);
  //       }
  //       setTimeSlotLoading(false);
  //     })
  //     .catch((error) => {
  //       setTourTimeSlot([]);
  //     });
  // };

  const getTotalFare = (tourList) => {
    return tourList
      ?.reduce((a, b) => a + parseFloat(b.serviceTotal), 0)
      .toFixed(2);
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    getTicketDetails();
    getCmsFareRules(5);
  }, [ticketSearchParams.ref]);

  const submitCancelForm = (val) => {
    setLoading(true);

    let data = {
      traceId: "string",
      tourOptionId: val.optionId,
      referenceNo: ticketData?.TourDetails?.[0].referenceNumber,
      cancellationReason: "Test cancellation",
    };
    APIClient.post("activities/tours/cancellation", data)
      .then((res) => {
        setLoading(false);

        if (res.statusCode == 200) {
          message.success(res.message, 10);
          getTicketDetails();
          setModalVisible(false);

          form.resetFields();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getCmsFareRules = (serviceType) => {
    APIClient.get(`admin/getFareRulesbyserviceType/${serviceType}`)
      .then((res) => {
        if (res.status == 200) {
          setCmsFareRules(res.data);
        } else {
          setCmsFareRules({});
        }
      })
      .catch((e) => {
        setCmsFareRules({});
      });
  };

  return (
    <div className="flight-ticket-collapse">
      <div className="d-flex justify-content-between">
        <div className="print-agentlogo">
          {logoImage ? (
            <Image
              className="printImageStyle"
              src={BASE + logoImage.substring(1)}
            ></Image>
          ) : (
            <Image className="printImageStyle" src={LogoImg}></Image>
          )}
        </div>
        <div className="print-agentlogo">
          <div>
            {ticketData?.agentDetails ? (
              <div>
                <p className="mb-0 text-right">
                  {ticketData?.agentDetails?.AgencyName}{" "}
                </p>
                <p className="mb-0 text-right">
                  {ticketData?.agentDetails?.Address1}{" "}
                </p>
                <p className="mb-0 text-right">
                  {ticketData?.agentDetails?.Address2}
                </p>
                {ticketData?.agentDetails?.City ||
                  ticketData?.agentDetails?.State ? (
                  <p className="mb-0 text-right">
                    {ticketData?.agentDetails?.City}{" "}
                    {ticketData?.agentDetails?.State}
                  </p>
                ) : null}

                <p className="mb-0 text-right">
                  Email: {ticketData?.agentDetails?.Email}
                </p>
                {ticketData?.agentDetails?.Phone ? (
                  <p className="mb-0 text-right">Contact No.: {ticketData?.agentDetails?.Phone}</p>
                ) : null}
              </div>
            ) : ticketData?.adminDetails?.length > 0 ? (
              <div>
                <p className="mb-0 text-right">
                  {ticketData?.adminDetails[0]?.Address}{" "}
                </p>
                <p className="mb-0 text-right">
                  {ticketData?.adminDetails[0]?.CityName}{" "}
                  {ticketData?.adminDetails[0]?.StateName}
                </p>
                <p className="mb-0 text-right">
                  {ticketData?.adminDetails[0]?.CountryName}{" "}
                  {ticketData?.adminDetails[0]?.PostalCode}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Card>
        <div className="fligh-ticket-container">
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : ticketData.TourDetails.length > 0 ? (
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18}>
                  <Collapse
                    bordered={true}
                    className="collapsed-data"
                    defaultActiveKey={["1"]}
                    accordion={true}
                  >
                    <Panel key="1">
                      <TicketActivites
                        ticketData={ticketData}
                        cmsFareRules={cmsFareRules}
                        voucherData={voucherData}
                      />
                    </Panel>
                  </Collapse>
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Activities"
                      onCancelTicket={cancelTicket}
                      getTicketDetails={getTicketDetails}
                      logoImage={logoImage}
                      toggleVoucheredModal={toggleVoucheredModal}
                      VoucheredModalVisible={VoucheredModalVisible}
                      VoucheredConfirm={VoucheredConfirm}
                      voucherData={voucherData}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Activities"
                          onCancelTicket={cancelTicket}
                          getTicketDetails={getTicketDetails}
                          logoImage={logoImage}
                          toggleVoucheredModal={toggleVoucheredModal}
                          VoucheredModalVisible={VoucheredModalVisible}
                          VoucheredConfirm={VoucheredConfirm}
                          voucherData={voucherData}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p>No Ticket Found </p>
          )}
        </div>
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {ticketData?.TourDetails?.length > 0 ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> :{" "}
              {ticketData?.TourDetails?.[0].referenceNumber}
            </p>
            {/* <p>
              <b> Provider Ref No:</b> :{" "}
              {ticketData?.TourDetails?.[0].ProviderRefNo}
            </p> */}
            {ticketData?.TourDetails?.[0].confirmationNo ? (
            <p>
              <b>Confirmation No:</b> :{" "}
              {ticketData?.TourDetails?.[0].confirmationNo}
            </p>
            ) : null}
            <p>
              <b>Total Fare</b> : Rs {getTotalFare(ticketData?.TourDetails)}
            </p>

            <Form layout="vertical" form={form} onFinish={submitCancelForm}>
              <Form.Item
                label="Choose Tour:"
                name="optionId"
                className="font-weight-bold"
                rules={[{ required: true, message: "Please Choose Tour" }]}
              >
                <Radio.Group>
                  <Row>
                    {ticketData?.TourDetails?.length > 0
                      ? ticketData?.TourDetails?.map((item, i) => {
                        if (item.BookingStatus === "CONFIRMED" || item.BookingStatus === "VOUCHERED") {
                          return (
                            <Radio
                              key={i}
                              value={item.optionId}
                              className="ml-0"
                            >
                              {item.optionName}
                            </Radio>
                          );
                        }
                      })
                      : ""}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        ) : null}
        <Col md={24} xs={24}>
          <Form.Item
            name="cancellationReason" // Make sure this matches the form data key
            label="Remarks"
            rules={[{ required: true, message: "Please provide a reason for cancellation" }]}
          >
            <TextArea rows={4} placeholder="Reason For Cancellation" />
          </Form.Item>
        </Col>
      </Modal>
    </div>
  );
};
export default ActivitiesTicketScreen;
